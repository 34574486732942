import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlsDefinition } from '../../interfaces/form-controls-definition.interface';

@Component({
  selector: 'dg-fb-fieldset',
  templateUrl: './fieldset.component.html',
})
export class FieldsetComponent {
  @Input() classes?: string;
  @Input() validate = false;
  @Input() group?: FormGroup;
  @Input() data?: FormControlsDefinition[];
}
