import { DropoffStateService } from '../../../book-dropoff/book-dropoff-data-access/book-dropoff-state.service';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

interface InfoContent {
  index: number;
  name: string;
  address: string;
  distance: string;
}
import type { Api } from '@domgen/dgx-components';
import { Origin } from '@domgen/dgx-components';

@Component({
  selector: 'claims-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, AfterViewInit {
  bounds;
  constructor(
    private cdr: ChangeDetectorRef,
    private _state: DropoffStateService
  ) {}
  @Input() origin: Origin;
  @Input() serviceProviders: Api.ServiceProvider[] = [];
  @Input() highlighted: number;
  @Input() selected?: number | null;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @ViewChildren(MapMarker) marker: QueryList<MapMarker>;

  vm$ = this._state.vm$;

  zoom = 18;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    center: new google.maps.LatLng(0, 0),
    zoomControl: true,
    clickableIcons: false,
    panControl: true,
    gestureHandling: 'cooperative',
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    mapTypeId: 'roadmap',
  };
  markers = [];
  originMarker;
  infoContent: InfoContent;
  mousePosition = {
    x: 0,
    y: 0,
  };

  originalMarkerOptions: google.maps.MarkerOptions = {
    icon: '/assets/svg/Icon-Location.svg',
  };

  ngOnInit() {
    this.setOriginCenter();
    this.setMarkers();
    this.setBounding();
  }

  ngAfterViewInit() {
    this.openInfo(this.highlighted);
  }

  setMarkers() {
    if (this.originMarker) {
      this.serviceProviders.map((p: Api.ServiceProvider) => {
        this.addMarker(p);
      });
    }
  }

  getMarkers() {
    return this.originMarker
      ? this.marker
          .toArray()
          .filter((m) => m.getPosition() !== this.originMarker.getPosition())
      : null;
  }

  getInfo(providers: Api.ServiceProvider[], index: number) {
    const provider = providers[index];
    return {
      index: index,
      name: provider.ServiceProviderCompanyName,
      address: provider.Address,
      distance: provider.Distance,
    };
  }

  setBounding() {
    this.bounds = new google.maps.LatLngBounds();
    if (this.originMarker) {
      const markers = [...this.serviceProviders, this.origin];
      markers.map((p: Api.ServiceProvider) => {
        const position = new google.maps.LatLng(p.Lat, p.Lng);
        this.bounds.extend(position);

        if (this.map) {
          this.map.fitBounds(this.bounds);
          this.map.panToBounds(this.bounds);
        }

        this.cdr.detectChanges();
      });
    }
  }

  getMapImageData() {
    const markers = this.getMarkers();
    if (markers) {
      const position = markers[this.selected].getPosition();
      const origin = this.originMarker.getPosition();
      const mapId = this.map.googleMap.getMapTypeId();
      const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${position.lat()},${position.lng()}&size=420x240&maptype=${mapId}&markers=color:0x663399|${position.lat()},${position.lng()}&markers=size:mid%7Ccolor:red|${origin.lat()},${origin.lng()}&key=AIzaSyCmV5zat2m9BlT90fZNY3IIc6X-sxsXMaM`;

      this._state.setStaticMapUrl(staticMapUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.origin?.previousValue ||
      changes?.serviceProviders?.previousValue
    ) {
      this.markers = [];
      this.setOriginCenter();
      this.setMarkers();
      this.setBounding();
      this._state.setSelected(null);
      this.openInfo(0);
    }

    if (changes?.highlighted?.previousValue !== undefined) {
      this.openInfo(this.highlighted);
    }

    if (
      changes?.selected?.previousValue !== undefined &&
      changes?.selected?.currentValue !== null
    ) {
      this.getMapImageData();
    }
  }

  setOriginCenter() {
    if (this.origin?.Lat && this.origin?.Lng) {
      this.center = {
        lat: this.origin?.Lat,
        lng: this.origin?.Lng,
      };

      this.originMarker = new google.maps.Marker({
        position: {
          lat: this.origin?.Lat,
          lng: this.origin?.Lng,
        },
      });
    }
  }

  onMouseDown($event) {
    this.mousePosition.x = $event.screenX;
    this.mousePosition.y = $event.screenY;
  }

  closeInfo($event) {
    const clickedInsideInfo = !!$event?.target?.closest('.info');

    if (
      !clickedInsideInfo &&
      // dont close info on drag with tolerance of 5
      Math.abs(this.mousePosition.x - $event.screenX) <= 5 &&
      Math.abs(this.mousePosition.y - $event.screenY) <= 5
    ) {
      this.info.close();
    }
  }

  openInfo(index: number) {
    if (this.originMarker) {
      const info = this.getInfo(this.serviceProviders, index);
      const markers = this.getMarkers();
      this.infoContent = info;
      this.infoContent.index = index;
      this.cdr.detectChanges();
      this._state.setHighlighted(index);
      setTimeout(() => {
        this.info.open(markers[index]); // push top end of stack
      });
    }
  }

  addMarker(p: Api.ServiceProvider) {
    this.markers.push({
      position: {
        lat: p.Lat,
        lng: p.Lng,
      },
      title: p.ServiceProviderCompanyName,
      info: {
        name: p.ServiceProviderCompanyName,
        address: p.Address,
        distance: p.Distance,
      },
      options: {
        icon: '/assets/svg/Icon-Pointer-Default.svg',
      },
      zIndex: 1,
    });
  }
}
