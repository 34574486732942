import { ClaimFacade } from './claim.facade';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, zip } from 'rxjs';
import {
  map,
  switchMap,
  flatMap,
  catchError,
  tap,
  first,
  filter,
} from 'rxjs/operators';

/* NgRx */
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BasicErrorKeys, LocalStorageService } from '@domgen/dgx-components';
import { Router, NavigationEnd } from '@angular/router';

import * as fromClaims from './';
import * as claimActions from './claim.actions';
import {
  ClaimEffectHelperService,
  ProdAttrDropDownTypes,
  ClaimQAStateTypes,
} from '../services/claim-effect-helper.service';
import { SpecialRoutingService } from '../services/special-routing.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import {
  Claim,
  ClaimStage,
  ClaimJourneyStateType,
  UpdateClaimPayload,
  RouteClaimParams,
  ClaimBundleType,
  ClaimSelection,
} from '@domgen/dgx-components';
import { ErrorMessages } from '@domgen/dgx-components';
import { ApiService } from '../services/api.service';
import { Api } from '@domgen/dgx-components';
import {
  PutRepairData,
  SetOneProductType,
  GetSuperCategorySuccess,
  GetReflectDataSuccess,
  LoadClaimDataSuccess,
} from './claim.actions';

@Injectable()
export class ClaimEffects {
  constructor(
    private claimService: ApiService,
    private actions$: Actions,
    private ls: LocalStorageService,
    private router: Router,
    private store: Store<fromClaims.State>,
    private helper: ClaimEffectHelperService,
    private routing: SpecialRoutingService,
    private errorHandler: ErrorHandlerService,
    private readonly claimFacade: ClaimFacade
  ) {}

  initializeClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.InitializeCurrentClaim),
      switchMap(
        (action: {
          type: string;
          payload: { param: string; newHandoff: boolean };
        }) => zip(of(action.payload), this.claimService.getReflectData())
      ),
      map(([payload, reflect]) => {
        return claimActions.GetReflectDataSuccess({
          payload: {
            reflect: reflect as Api.Reflect,
            bundleToken: payload.param as string,
            newHandoff: payload.newHandoff,
          },
        });
      }),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  getSuperCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetReflectDataSuccess),
      switchMap((action: { type: string; payload: GetReflectDataSuccess }) => {
        const { reflect } = action.payload;
        const adminScheme = this.claimFacade.getAdminScheme(reflect);

        // only filter out X2R admin schemes,
        // leave routeForward to handle other schemes once claim is retrieved
        if (adminScheme?.SchCode === 'X2R') {
          return of(
            claimActions.LoadClaimDataSuccess({
              payload: {
                reflect: action.payload.reflect as Api.Reflect,
              },
            })
          );
        }

        if (reflect.journeyState === ClaimJourneyStateType.START) {
          return this.claimService
            .getSuperCategory(action.payload.reflect.applianceCode as string)
            .pipe(
              map((res: Api.GetSuperCategoryResponse) =>
                claimActions.GetSuperCategorySuccess({
                  payload: {
                    isGasAppliance: res.isGasAppliance,
                    applianceSuperCategory: res.applianceSuperCategory,
                  },
                })
              ),
              catchError(() => {
                return of(
                  claimActions.GetSuperCategorySuccess({
                    payload: {
                      isGasAppliance: false,
                      applianceSuperCategory: 'UNKNOWN',
                    },
                  })
                );
              })
            );
        }

        return of(claimActions.StartTransaction());
      })
    )
  );

  getProductAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetSuperCategorySuccess),
      // getActiveClaim from store to allow reducer logic to make changes to payload
      switchMap((action) =>
        zip(
          of(action),
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          this.claimService.getProductAttributes() as Observable<Api.GetProductAttributesResponse>
        )
      ),
      switchMap(
        ([action, claim, productAttributes]: [
          { type: string; payload: GetSuperCategorySuccess },
          Claim,
          Api.GetProductAttributesResponse
        ]) => {
          /* TODO: This actions array would be best split out into separate chained effects
      so that RouteClaim could be called at the end, instead of this.routing.routeForward() */
          const actions: Action[] = [
            claimActions.GetProductAttributesSuccess(),
          ];

          const manufacturers = this.helper.extractDropDownValues(
            ProdAttrDropDownTypes.Manufacturers,
            productAttributes
          );
          actions.push(
            claimActions.SetManufacturers({ payload: manufacturers })
          );

          const productTypes = this.helper.extractDropDownValues(
            ProdAttrDropDownTypes.ProductTypes,
            productAttributes
          );

          this.claimFacade.setProductTypes(productTypes);

          const needEnterManufacturer = this.routing.needEnterManufacturer(
            manufacturers as string[],
            claim
          );

          // autoset productType if we have one
          if (productTypes && productTypes?.length === 1) {
            actions.push(
              claimActions.AutoSetProductType({
                payload: productTypes[0],
              })
            );
          }

          // productType && manufacturer is valid
          // StartTransaction
          if (
            this.routing.isProductTypeValid(claim, productTypes as string[]) &&
            !needEnterManufacturer
          ) {
            actions.push(claimActions.StartTransaction());
          } else {
            actions.push(
              claimActions.RouteClaim({
                payload: {
                  claim,
                  manufacturers,
                  productTypes,
                  triggeredBy: action.type,
                },
              })
            );
          }

          return actions;
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  setProductType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.SetOneProductType),
      switchMap((action: { type: string; payload: SetOneProductType }) => {
        return action.payload.startTransaction
          ? [claimActions.StartTransaction()]
          : [];
      })
    )
  );

  autoSetProductType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimActions.ClaimActionTypes.AutoSetProductType),
        tap((action: { type: string; payload: string }) =>
          this.claimFacade.setOneProductType({
            productType: action.payload,
            startTransaction: false,
          })
        )
      ),
    { dispatch: false }
  );

  updateManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.UpdateManufacturer),
      switchMap(() => this.claimFacade.productTypes$),
      map((productTypes: string[] = []) =>
        productTypes?.length > 1
          ? claimActions.RouteForward()
          : claimActions.StartTransaction()
      )
    )
  );

  startTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.StartTransaction),
      switchMap(() => {
        return this.store
          .select(fromClaims.getActiveClaim)
          .pipe(first()) as Observable<Claim>;
      }),
      switchMap((claim: Claim) => {
        return this.claimService
          .startTransaction({
            PlanNumber: claim.reflect?.planNumber as string,
            Manufacturer: claim.reflect?.manufacturer as string,
            ProductType: this.helper.getProductType(claim),
          })
          .pipe(
            switchMap((startTransaction: Api.StartTransactionResponse) => {
              return zip(
                this.ls.setItem('GUID', startTransaction.GUID),
                this.ls.setItem('referrer', claim.reflect)
              );
            }),
            switchMap(() => of(claim))
          );
      }),
      switchMap((claim: Claim) => {
        return this.claimService
          .getMandatoryData(claim.reflect?.planNumber as string)
          .pipe(
            flatMap((data: Api.GetMandatoryDataResponse) => {
              if (claim.reflect?.journeyState === ClaimJourneyStateType.QA) {
                return this.claimService
                  .getSuperCategory(claim.reflect.applianceCode as string)
                  .pipe(
                    map((res: Api.GetSuperCategoryResponse) =>
                      claimActions.GetClaimQA({
                        payload: {
                          getData: data,
                          superCat: res,
                        },
                      })
                    ),
                    catchError(() => {
                      return of(
                        claimActions.GetClaimQA({
                          payload: {
                            getData: data,
                            superCat: {
                              isGasAppliance: false,
                              applianceSuperCategory: 'UNKNOWN',
                            },
                          },
                        })
                      );
                    })
                  );
              }

              if (
                claim.reflect?.journeyState === ClaimJourneyStateType.REBOOKING
              ) {
                return of(claimActions.ClaimRebook({ payload: data }));
              }

              if (
                claim.reflect?.journeyState === ClaimJourneyStateType.BOOKING
              ) {
                return of(
                  claimActions.LoadClaimDataSuccess({
                    payload: {
                      reflect: claim.reflect as Api.Reflect,
                      claimID: claim.reflect?.claimId as string,
                      getData: data,
                      created: true,
                      accepted: true,
                    },
                  })
                );
              }

              return of(
                claimActions.LoadClaimDataSuccess({
                  payload: {
                    reflect: claim.reflect as Api.Reflect,
                    getData: {
                      ...data,
                      OEMApplianceData: claim?.getData
                        ? claim?.getData?.OEMApplianceData
                        : data?.OEMApplianceData,
                    },
                    claimSelection: {
                      ...claim.claimSelection,
                      selectionState: {
                        ...claim?.claimSelection?.selectionState,
                        modelNumberAlreadyValidated:
                          claim?.claimSelection?.selectionState
                            .modelNumberAlreadyValidated === undefined
                            ? this.helper.isInitialModelNumberValid(data)
                            : claim?.claimSelection?.selectionState
                                .modelNumberAlreadyValidated,
                      },
                    } as ClaimSelection,
                  },
                })
              );
            })
          );
      }),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  getClaimQA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetClaimQA),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }),
      switchMap(
        ([claim, action]: [
          Claim,
          {
            type: string;
            payload: {
              getData: Api.GetMandatoryDataResponse;
              superCat: Api.GetSuperCategoryResponse;
            };
          }
        ]) => {
          const unansweredQuestionsCached = claim?.questions?.some(
            (q) => q.answer === null
          );

          if (unansweredQuestionsCached) {
            return of(
              claimActions.LoadClaimDataSuccess({
                payload: {
                  reflect: claim?.reflect as Api.Reflect,
                  getData: action.payload.getData,
                  claimID: claim.claimID
                    ? claim.claimID
                    : (claim?.reflect?.claimId as string),
                  created: true,
                  accepted: false,
                  questions: claim.questions,
                },
              })
            );
          }

          if (claim.accepted) {
            return of(
              claimActions.LoadClaimDataSuccess({
                payload: {
                  reflect: claim.reflect as Api.Reflect,
                  getData: action.payload.getData,
                  claimID: claim.claimID
                    ? claim.claimID
                    : (claim.reflect?.claimId as string),
                  created: true,
                  accepted: true,
                  questions: claim.questions,
                },
              })
            );
          }

          return this.claimService
            .getQuestion({
              ClaimID: claim.reflect?.claimId as string,
              QuestionID: null,
            })
            .pipe(
              switchMap((response: Api.GetQuestionResponse) => {
                if (this.helper.isQuestionValid(response)) {
                  return of(
                    claimActions.LoadClaimDataSuccess({
                      payload: {
                        reflect: claim.reflect as Api.Reflect,
                        getData: action.payload.getData,
                        claimID: claim.claimID
                          ? claim.claimID
                          : (claim.reflect?.claimId as string),
                        created: true,
                        accepted: false,
                        questions: [{ question: response, answer: null }],
                      },
                    })
                  );
                }

                return of(
                  claimActions.Error({
                    payload: new Error(ErrorMessages.InvalidQuesionType),
                  })
                );
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  automaticApplianceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.LoadClaimDataSuccess),
      switchMap((action: { type: string; payload: LoadClaimDataSuccess }) => {
        const getData = action.payload.getData as Api.GetMandatoryDataResponse;
        // for first LoadClaimDataSuccess (claimSelection undefined)...
        // If like Whirlpool MB, Update transaction with Unique Appliance ID from GetMandatoryData
        return !action?.payload.claimSelection &&
          getData &&
          this.helper.isAutomaticApplianceId(getData) &&
          action.payload.reflect.journeyState === ClaimJourneyStateType.START
          ? [
              claimActions.UpdateClaim({
                payload: {
                  request: {
                    Manufacturer: action.payload.reflect.manufacturer,
                    ProductType: action.payload.reflect.productType,
                    UniqueApplianceID: getData?.UniqueApplianceID as string,
                  },
                  selectionState: {
                    claimStage: ClaimStage.AutomaticApplianceId,
                  },
                  route: false, // already routed from CreateTransaction
                },
              }),
            ]
          : []; // no action required
      })
    )
  );

  routeClaimFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.LoadClaimDataSuccess,
        claimActions.ClaimActionTypes.UpdateClaimSuccess,
        claimActions.ClaimActionTypes.PutServiceOptionSuccess,
        claimActions.ClaimActionTypes.DropOffPutServiceOptionSuccess,
        claimActions.ClaimActionTypes.PutRepairDataSuccess,
        claimActions.ClaimActionTypes.SeenGasSafetyNotice,
        claimActions.ClaimActionTypes.SeenMaintenanceSignpost,
        claimActions.ClaimActionTypes.CreateClaimSuccess,
        claimActions.ClaimActionTypes.RebookClaimDataSuccess,
        claimActions.ClaimActionTypes.CompleteCallbackBookingSuccess,
        claimActions.ClaimActionTypes.LoadCardPaymentRedirectSuccess,
        claimActions.ClaimActionTypes.RouteForward
      ),
      switchMap((action) =>
        zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          this.store.select(fromClaims.getManufacturers).pipe(first()),
          this.claimFacade.productTypes$,
          of(action)
        )
      ),
      switchMap(([claim, manufacturers, productTypes, action]) => {
        return (<any>action)?.payload?.route === false
          ? [claimActions.Loaded()]
          : [
              claimActions.RouteClaim({
                payload: {
                  claim,
                  manufacturers,
                  productTypes,
                  triggeredBy: action.type,
                },
              }),
            ];
      })
    )
  );

  routeClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.RouteClaim),
      tap(({ payload }: { payload: RouteClaimParams }) => {
        this.routing.routeForward(payload);
      }),
      switchMap(() =>
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd)
        )
      ),
      map(() => {
        return claimActions.Loaded();
      })
    )
  );

  loadingFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.SeenMaintenanceSignpost,
        claimActions.ClaimActionTypes.SeenGasSafetyNotice,
        claimActions.ClaimActionTypes.UpdateManufacturer,
        claimActions.ClaimActionTypes.SetOneProductType,
        claimActions.ClaimActionTypes.GetServiceOptions,
        claimActions.ClaimActionTypes.UpdateClaim,
        claimActions.ClaimActionTypes.CreateClaim,
        claimActions.ClaimActionTypes.PutAnswer,
        claimActions.ClaimActionTypes.ContactDetailsCallbackClaim,
        claimActions.ClaimActionTypes.GetCallbackWidget,
        claimActions.ClaimActionTypes.LoadCardPaymentRedirect,
        claimActions.ClaimActionTypes.CompletePayment,
        claimActions.ClaimActionTypes.PutRepairData
      ),
      map(() => claimActions.Loading({ payload: null }))
    )
  );

  /**
   * turn off spinner for actions who's completed action is not in the route claim funnel
   */
  loadCompleteFunnel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.ClaimAccepted,
        claimActions.ClaimActionTypes.GetBookingExtraSlotsSuccess,
        claimActions.ClaimActionTypes.BookingSlotUnavailable,
        claimActions.ClaimActionTypes.GetBookingExtraSlotsFailed,
        claimActions.ClaimActionTypes.ClaimRejected,
        claimActions.ClaimActionTypes.LoadServiceOptions,
        claimActions.ClaimActionTypes.GetQuestionSuccess,
        claimActions.ClaimActionTypes.CreateCallbackClaim,
        claimActions.ClaimActionTypes.GetCallbackWidgetSuccess,
        claimActions.ClaimActionTypes.ContactDetailsCallbackClaimSuccess,
        claimActions.ClaimActionTypes.Error
      ),
      map(() => claimActions.Loaded())
    )
  );

  updateClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.UpdateClaim),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }),
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: UpdateClaimPayload }
        ]) => {
          return this.helper
            .mergeRequestUpdateTransaction(action.payload.request)
            .pipe(
              switchMap(() => {
                return this.claimService
                  .getMandatoryData(claim.reflect?.planNumber as string)
                  .pipe(
                    flatMap((data: Api.GetMandatoryDataResponse) => {
                      if (
                        this.helper.isAnnualServiceRequest(
                          action.payload.request.ClaimTypeID,
                          claim.getData?.ClaimTypes as Api.ClaimType[],
                          claim.reflect?.claimType as ClaimBundleType
                        )
                      ) {
                        return of(
                          claimActions.AutoCreateClaim({
                            payload: {
                              isASV: true,
                              request: this.helper.getAnnualServicePayload(
                                action.payload.request,
                                data.ClaimTypes,
                                data.FaultData
                              ),
                              selectionState: {
                                claimStage: ClaimStage.CreateClaim,
                              },
                              getData: data,
                            },
                          })
                        );
                      }

                      // if no fault data exists create the claim or
                      // we have one fault data with one answer
                      if (
                        this.helper.isSingleClaim(
                          data.ClaimTypes,
                          claim?.reflect as Api.Reflect
                        ) &&
                        (this.helper.isRepairWithNoFaultData(data.FaultData) ||
                          this.helper.isRepairWithSingleAnswerFaultData(
                            data.FaultData
                          ))
                      ) {
                        return of(
                          claimActions.AutoCreateClaim({
                            payload: {
                              request: this.helper.getAutoCreateClaimPayload(
                                action.payload.request,
                                data.ClaimTypes,
                                data.FaultData
                              ),
                              selectionState: {
                                claimStage: ClaimStage.CreateClaim,
                              },
                              getData: data,
                            },
                          })
                        );
                      }

                      // If no fault data exists but we have 2 or more claimTypes
                      // and were attempting to make claim selection which returns no
                      // fault data then auto create claim
                      if (
                        action.payload.request.ClaimTypeID !== undefined &&
                        this.helper.isRepairWithNoFaultData(data.FaultData)
                      ) {
                        return of(
                          claimActions.AutoCreateClaim({
                            payload: {
                              request: {
                                ClaimTypeID: action.payload.request.ClaimTypeID,
                              },
                              selectionState: {
                                claimStage: ClaimStage.CreateClaim,
                              },
                              getData: data,
                            },
                          })
                        );
                      }

                      return of(
                        claimActions.UpdateClaimSuccess({
                          payload: {
                            getData: {
                              ...data,
                              OEMApplianceData: claim?.getData
                                ? claim?.getData?.OEMApplianceData
                                : data?.OEMApplianceData,
                            },
                            update: action.payload,
                            route: action.payload.route as boolean,
                          },
                        })
                      );
                    })
                  );
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  createClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimActions.ClaimActionTypes.CreateClaim,
        claimActions.ClaimActionTypes.AutoCreateClaim
      ),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }),
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: UpdateClaimPayload }
        ]) => {
          return this.helper
            .mergeRequestUpdateTransaction(action.payload.request)
            .pipe(
              switchMap(() => {
                return this.claimService
                  .getMandatoryData(claim.reflect?.planNumber as string)
                  .pipe(
                    switchMap((response: Api.GetMandatoryDataResponse) => {
                      if (response?.IWCallbackRequired) {
                        return of(
                          claimActions.CreateCallbackClaim({
                            payload: response,
                          })
                        );
                      }

                      return this.claimService
                        .putNewClaim({
                          CustomerTitle: claim.reflect?.customerTitle as string,
                          CustomersEmail: claim.reflect?.email as string,
                          CustomersMobile: claim.reflect?.mobilePhone as string,
                          CustomersLandLine: claim.reflect
                            ?.homeTelephone as string,
                          CustomerFirstName: claim.reflect?.firstName || '', // firstName can be undefined
                          CustomerLastName: claim.reflect?.surname as string,
                          CustomersHouseStreetName: claim.reflect
                            ?.addressLine1 as string,
                          CustomersLocalArea: claim.reflect
                            ?.addressLine2 as string,
                          CustomersTownCity: claim.reflect
                            ?.addressLine3 as string,
                          CustomersPostCode: claim.reflect?.postCode as string,
                        })
                        .pipe(
                          switchMap((response: Api.PutNewClaimResponse) => {
                            return of(
                              claimActions.GetQuestion({
                                payload: {
                                  response: response,
                                  update: action.payload,
                                },
                              })
                            );
                          })
                        );
                    })
                  );
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  getQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.GetQuestion),
      switchMap(
        (action: {
          type: string;
          payload: {
            response: Api.PutNewClaimResponse;
            update: UpdateClaimPayload;
          };
        }) => {
          return this.claimService
            .getQuestion({
              ClaimID: action.payload.response.ClaimID,
              QuestionID: null,
            })
            .pipe(
              map((question: Api.GetQuestionResponse) => {
                if (this.helper.isQuestionValid(question)) {
                  return claimActions.CreateClaimSuccess({
                    payload: {
                      response: {
                        ClaimID: action.payload.response.ClaimID,
                        question: question,
                      },
                    },
                  });
                }

                return claimActions.Error({
                  payload: new Error(ErrorMessages.InvalidQuesionType),
                });
              })
            );
        }
      ),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  putAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.PutAnswer),
      switchMap((action: { type: string; payload: Api.PutAnswerRequest }) => {
        return this.claimService.putAnswer(action.payload).pipe(
          switchMap((response: Api.PutAnswerResponse) => {
            if (response.ClaimState === ClaimQAStateTypes.Rejected) {
              return of(
                claimActions.ClaimRejected({
                  payload: {
                    rejectedMessage: response.ClaimStateMessage,
                  },
                })
              );
            }

            if (response.ClaimState === ClaimQAStateTypes.Accepted) {
              return of(claimActions.ClaimAccepted());
            }

            return this.claimService
              .getQuestion({
                ClaimID: action.payload.ClaimID,
                QuestionID: response.NextQuestionID,
              })
              .pipe(
                map((question: Api.GetQuestionResponse) => {
                  if (this.helper.isQuestionValid(question)) {
                    return claimActions.GetQuestionSuccess({
                      payload: {
                        request: action.payload,
                        response: question,
                      },
                    });
                  }

                  return claimActions.Error({
                    payload: new Error(ErrorMessages.InvalidQuesionType),
                  });
                })
              );
          })
        );
      }),
      catchError((err) => of(claimActions.Error({ payload: err })))
    )
  );

  putRepairData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimActions.ClaimActionTypes.PutRepairData),
      switchMap((action) => {
        return zip(
          this.store
            .select(fromClaims.getActiveClaim)
            .pipe(first()) as Observable<Claim>,
          of(action)
        );
      }),
      switchMap(
        ([claim, action]: [
          Claim,
          { type: string; payload: PutRepairData }
        ]) => {
          const request = action.payload.isRebook
            ? this.claimService.rebookRepairOption({
                RepairDate: action.payload.request.AppointmentDate,
                RepairSlot: action.payload.request.AppointmentSlotIdentifier,
                ExtraDetails: action.payload.request.ExtraInformation,
              })
            : this.helper.updateCollectionData(action.payload.request)
            ? this.claimService
                .putServiceOption({
                  ClaimID: claim?.claimID as string,
                  ServiceOptionID: claim?.serviceOption
                    ?.ServiceOptionID as string,
                  ServiceOptionRequiredFields:
                    action.payload?.request?.CollectionData,
                  AvailabilityEndDate: '',
                  AvailabilityStartDate: '',
                })
                .pipe(
                  switchMap(() =>
                    this.claimService.putRepairData(action.payload.request)
                  ),
                  catchError((err) => throwError(err))
                )
            : this.claimService.putRepairData(action.payload.request);

          //todo: really we should be using type:
          //      `Api.PutRepairDataResponse|Api.PutServiceOptionResponse|Api.PutRepairDataResponse`
          //      and then transforming into a combined response with a new type `ConfirmedClaimData`
          return request.pipe(
            flatMap((response: Api.PutRepairDataResponse) =>
              of(
                claimActions.PutRepairDataSuccess({
                  payload: {
                    response: response,
                  },
                })
              )
            ),
            catchError((err: BasicErrorKeys) => {
              if (this.helper.bookingSlotUnavailable(err, claim)) {
                return of(claimActions.BookingSlotUnavailable());
              }

              if (this.helper.bookingCompletedError(err)) {
                return of(
                  claimActions.PutRepairDataSuccess({
                    payload: {
                      response: this.helper.getPutRepairDataFallback(claim),
                      errorResponse: true,
                    },
                  })
                );
              }

              if (this.helper.manualBookingErrorFallback(err)) {
                return of(
                  claimActions.ManualBooking({
                    payload: {
                      noBookingDatesFound: true,
                      serviceOptionID: String(
                        err.ManualReferralServiceOptionID
                      ),
                    },
                  })
                );
              }

              return of(claimActions.Error({ payload: err }));
            })
          );
        }
      )
    )
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimActions.ClaimActionTypes.Error),
        tap(({ payload }: { payload: BasicErrorKeys }) => {
          this.errorHandler.cacheError(payload);
          this.router.navigate(['/error'], {
            replaceUrl: true,
          });
        })
      ),
    { dispatch: false }
  );

  claimRejected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimActions.ClaimActionTypes.ClaimRejected),
        tap(({ payload }) => {
          this.errorHandler.cacheError(payload);
          // TODO: use the Error keys to pass in Rejection Reason
          this.router.navigate(['/reject'], {
            replaceUrl: true,
          });
        })
      ),
    { dispatch: false }
  );

  clearClaim$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimActions.ClaimActionTypes.ClearCurrentClaim),
        tap(() => {
          this.ls.removeItem('athome_aws_bundle');
        })
      ),
    { dispatch: false }
  );
}
