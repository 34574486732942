import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  filter,
  first,
  takeUntil,
} from 'rxjs/operators';
import {
  ContactDetailsLookupService,
  FormTemplateBuilder,
} from './contact-details-lookup.service';
import { BaseComponent } from '../base/base.component';
import { isEqual } from 'lodash-es';
import { fadeInAnimation, slideInOut } from '../../animations/animations';
import { PcaService } from '../../services/pca.service';
import { Claim } from '../../interfaces/claim-state.interface';
import {
  ContactDetailsFormValues,
  ContactDetailsFormValuesContainer,
} from '../../interfaces/contact-details.interface';
import { ClaimsAnalyticsService } from '../../services/claims-analytics.service';
import {
  AddressItem,
  AddressItemDetail,
} from '../../interfaces/address-lookup.interface';

@Component({
  selector: 'dg-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  providers: [ContactDetailsLookupService],
  animations: [slideInOut, fadeInAnimation],
})
export class ContactDetailsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() contactDetails?: ContactDetailsFormValuesContainer;
  @Input() claim?: Claim;
  @Input() placeholder?: string;
  @Input() searchLabel = 'Address';
  @Input() heading = 'Your details';
  @Input() defaultEdit = false;
  @Input() showCancelCTA = true;
  @Output() submitted: EventEmitter<ContactDetailsFormValues> =
    new EventEmitter();
  @Output() valid: EventEmitter<boolean> = new EventEmitter();
  @Output() editSave: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('error') errorEl?: ElementRef | null;
  @ViewChild('scrollList') scrollList?: ElementRef | null;
  @ViewChild('contactAddressInput') contactAddressInput?: ElementRef | null;

  addressForm?: FormGroup;
  isEdit = false;
  selected = 0;
  initFormDataValid = false;
  isManualAddress = true;
  addressList: AddressItem[] = [];
  formTemplateBuilder?: FormTemplateBuilder[];
  f?: {
    [key: string]: AbstractControl;
  }; // shortcut for form controls reference

  private addressFormSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private pca: PcaService,
    private analytics: ClaimsAnalyticsService,
    private lookup: ContactDetailsLookupService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.formTemplateBuilder = this.lookup.getFormBuilderList(
      this.contactDetails as ContactDetailsFormValuesContainer
    );

    this.createForm();

    if (this.defaultEdit) {
      this.edit({ emit: false });
    }

    if (this.addressForm?.valid) {
      this.submitted.emit(this.addressForm.value);
      this.initFormDataValid = true;
    }

    this.scrollToPosition();
  }

  onKeydown(event: Event, index: number, addressList: AddressItem[]) {
    if ((event as KeyboardEvent).key === 'Enter') {
      event.preventDefault();
      this.handleAddressClick(addressList[index]);
    } else if (
      (event as KeyboardEvent).key === 'ArrowDown' &&
      this.selected < addressList.length - 1
    ) {
      this.selected++;
      (this.scrollList as ElementRef).nativeElement.scrollTop =
        this.selected * 51 - 51;
    } else if (
      (event as KeyboardEvent).key === 'ArrowUp' &&
      this.selected > 0
    ) {
      this.selected--;
      (this.scrollList as ElementRef).nativeElement.scrollTop =
        this.selected * 51;
    }
  }

  formatCase(name: string, ctrl: FormControl) {
    if (name === 'postcode') {
      ctrl.patchValue(ctrl.value.toUpperCase());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !isEqual(
        changes?.contactDetails?.currentValue,
        changes?.contactDetails?.previousValue
      )
    ) {
      this.createForm();
    }
  }

  createForm() {
    this.addressForm = this.fb.group(
      this.lookup.getLookupFormGroup(
        this.contactDetails as ContactDetailsFormValuesContainer
      )
    );

    if (this.addressForm.invalid) {
      this.edit();
      this.isManualAddress = true;
      this.addressForm.markAllAsTouched();
    }

    this.f = this.addressForm.controls;
  }

  edit(config = { emit: true }) {
    if (!this.isEdit) {
      this.onEditGA();
    }

    this.isEdit = true;
    this.isManualAddress = false;
    if (config.emit) this.valid.emit(false);
    this.initCapturePlusField();
    this.focus();
  }

  private focus() {
    setTimeout(() => {
      const target = this.contactAddressInput?.nativeElement;
      if (target) {
        target.focus();
        target.click();
      }
    }); // push end of stack
  }

  private initCapturePlusField() {
    // subscribe to address field changes for Capture+
    if (this.addressFormSub) {
      this.addressFormSub.unsubscribe();
    }
    this.addressFormSub = this.addressForm
      ?.get('address')
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        map((input: string) => input.trim()),
        distinctUntilChanged(),
        debounceTime(200),
        tap((input: string) => {
          if (input.length === 0) {
            this.addressList = [];
            this.cdr.detectChanges();
          }
        }),
        filter((input) => !!input),
        switchMap((input: string) =>
          this.pca.pcaFind(input).pipe(
            map(({ Items = [] }: { Items: AddressItem[] }) => {
              this.addressList = [...Items];
              this.cdr.detectChanges();
            })
          )
        )
      )
      .subscribe();
  }

  handleAddressClick(addressLookup: AddressItem) {
    if (addressLookup?.Type !== null && addressLookup?.Type === 'Address') {
      this.addressList = [];

      this.pca
        .pcaRetrieve(addressLookup.Id)
        .pipe(
          takeUntil(this.destroy$),
          first(),
          map((res) => {
            return res.Items[0];
          })
        )
        .subscribe((res: AddressItemDetail) => {
          this.addressForm
            ?.get('address')
            ?.setValue(
              `${res.Line1} ${res.Line2} ${res.City} ${res.PostalCode}`,
              { emitEvent: false }
            );

          this.addressForm?.patchValue({
            addressLine1: res.Line1,
            addressLine2: res.Line2,
            city: res.City,
            county: res.County ? res.County : '',
            postcode: res.PostalCode,
          });

          this.submit();
        });
    } else if (addressLookup?.Text) {
      this.pca
        .pcaFind(addressLookup.Text, addressLookup.Id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ Items }) => {
          this.addressList = [...Items];
          this.selected = 0;
          (this.scrollList as ElementRef).nativeElement.scrollTop = 0;
          this.cdr.detectChanges();
        });
    }
  }

  handleExpandable(address: AddressItem): boolean {
    return !!(address.Type === 'Address' || address.Next === 'Retrieve');
  }

  toggleAddress() {
    this.focus();
    this.isManualAddress = !this.isManualAddress;
    if (!this.isManualAddress) {
      this.initCapturePlusField();
    }
  }

  submit() {
    if (this.addressForm?.valid) {
      this.contactDetails = {
        formValues: this.addressForm?.value as ContactDetailsFormValues,
      };
      this.addressForm?.get('address')?.setValue('');
      this.addressList = [];
      this.isEdit = false;
      this.initFormDataValid = true;
      this.valid.emit(true);
      this.submitted.emit(this.addressForm.value);
      this.editSave.emit(true);
    } else {
      this.addressForm?.markAllAsTouched();
      this.valid.emit(false);
      this.getFormValidationMessages().forEach((m) => {
        this.triggerGAError(m);
      });

      setTimeout(() => {
        // dom ready
        if (this.errorEl?.nativeElement) {
          this.errorEl.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  cancel() {
    if (this.initFormDataValid) {
      this.isEdit = false;
      this.isManualAddress = false;
      this.createForm();
      this.valid.emit(true);
    }
  }

  triggerGAError(validationMessage: string) {
    this.analytics.claimEventExtended({
      eventClaims: 'validation-error',
      eventAction: 'validation-error-dgx',
      eventLabel: validationMessage,
      claim: this.claim as Claim,
    });
  }

  onEditGA() {
    this.analytics.claimEventExtended({
      eventClaims: 'contact-details-edit',
      eventAction: 'edit-personal-details',
      eventLabel: 'edit-personal-details',
      claim: this.claim as Claim,
    });
  }

  private getFormValidationMessages() {
    const validationMessages: string[] = [];
    for (const key in this.addressForm?.controls) {
      if (!this.addressForm?.controls[key].valid) {
        const formBuilderElement = this.lookup.getFormBuilderElement(
          key,
          this.contactDetails as ContactDetailsFormValuesContainer
        );
        if (this.addressForm?.controls[key].errors?.required) {
          validationMessages.push(formBuilderElement.requiredMessage);
        }
        if (this.addressForm?.controls[key].errors?.pattern) {
          validationMessages.push(formBuilderElement.patternMessage);
        }
      }
    }
    return validationMessages;
  }

  scrollToPosition() {
    setTimeout(() => {
      // user experience delay
      const region = document.getElementById('ContactSection');
      if (region) {
        region.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }
}
