import { Injectable } from '@angular/core';
import { ConfigService, Config } from '@domgen/dgx-components';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderLinksService {
  constructor(private configService: ConfigService) {}

  links$ = of(this.configService.config).pipe(
    map((conf: Config | null) => {
      return {
        homeURL: conf?.dgHomePage,
        plansURL: conf?.plansHomePage,
        personalDetailsURL: conf?.personalDetailsPage,
        salesURL: conf?.salesAppHost,
      };
    })
  );
}
