<div class="booking-calendar">
  <dg-booking-calendar-header
    [firstDate]="firstDate"
    [slotType]="slotType"
    (selectFirstDate)="onSelectFirstDate()"
  ></dg-booking-calendar-header>
  <dg-booking-calendar-navigation
    [weeks]="weeks | async"
    [pager]="pager"
    [extraAvailibilty]="availability?.extraAvailability"
    (navigate)="navigate($event)"
  ></dg-booking-calendar-navigation>

  <dg-booking-calendar-day
    [@fadeInAnimation]
    *ngFor="let day of weeks | async; let i = index"
    [day]="day"
    [slotType]="slotType"
    (daySelected)="onDaySelected($event)"
    (selected)="onDaySlotSelected($event)"
  >
  </dg-booking-calendar-day>
</div>
