import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_shared/guards/auth.guard';
import { ApplianceDetailsGuard } from './_shared/guards/appliance-details.guard';
import { LoaderComponent } from '@domgen/dgx-components';
import { RepairDetailsGuard } from './_shared/guards/repair-details.guard';
import { ErrorGuard } from './_shared/guards/error.guard';
import { MaintenanceSignpostGuard } from './_shared/guards/maintenance-signpost.guard';
import { PaymentGuard } from './_shared/guards/payment.guard';

export const routes: Routes = [
  {
    path: '',
    component: LoaderComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard, MaintenanceSignpostGuard],
    loadChildren: () =>
      import('@domgen/feature-maintenance-signpost').then(
        (m) => m.ClaimsFeatureMaintenanceSignpostModule
      ),
  },
  {
    path: 'gas-safety-notice',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-gas-safety').then(
        (m) => m.ClaimsFeatureGasSafetyModule
      ),
  },
  {
    path: 'call-service-provider',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-call-oem').then(
        (m) => m.ClaimsFeatureCallOemModule
      ),
  },
  {
    path: 'book-collection',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-book-collection').then(
        (m) => m.ClaimsFeatureBookEngineerModule
      ),
  },
  {
    path: 'book-callback',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-book-callback').then(
        (m) => m.ClaimsFeatureBookCallbackModule
      ),
  },
  {
    path: 'book-dropoff',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./book-dropoff/book-dropoff.module').then(
        (m) => m.BookDropOffModule
      ),
  },
  {
    path: 'book-selfsend',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-book-selfsend').then(
        (m) => m.ClaimsFeatureBookSelfsendModule
      ),
  },
  {
    path: 'book-engineer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-book-engineer').then(
        (m) => m.ClaimsFeatureBookEngineerModule
      ),
  },
  {
    path: 'appliance-details',
    canActivate: [AuthGuard, ApplianceDetailsGuard],
    loadChildren: () =>
      import('@domgen/feature-appliance-details').then(
        (m) => m.ClaimsFeatureApplianceDetailsModule
      ),
  },
  {
    path: 'confirmation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-confirmation').then(
        (m) => m.ClaimsFeatureConfirmationModule
      ),
  },
  {
    path: 'enter-manufacturer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-enter-manufacturer').then(
        (m) => m.ClaimsFeatureEnterManufacturerModule
      ),
  },
  {
    path: 'product-type',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@domgen/feature-product-type').then(
        (m) => m.ClaimsFeatureProductTypeModule
      ),
  },
  {
    path: 'payment',
    canActivate: [AuthGuard, PaymentGuard],
    loadChildren: () =>
      import('@domgen/feature-excess-payment').then(
        (m) => m.ClaimsFeatureExcessPaymentModule
      ),
  },
  {
    path: 'repair-details',
    canActivate: [AuthGuard, RepairDetailsGuard],
    loadChildren: () =>
      import('@domgen/feature-repair-details').then(
        (m) => m.ClaimsFeatureRepairDetailsModule
      ),
  },
  {
    path: 'error',
    canActivate: [ErrorGuard],
    loadChildren: () =>
      import('@domgen/feature-error').then((m) => m.ClaimsFeatureErrorModule),
  },
  {
    path: 'reject',
    canActivate: [ErrorGuard],
    loadChildren: () =>
      import('@domgen/feature-error').then((m) => m.ClaimsFeatureErrorModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top', // Add options right here
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class ClaimsFeatureShellRoutingModule {}
