import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapTabComponent } from './components/map-tab/map-tab.component';
import { MapTabsComponent } from './components/map-tabs/map-tabs.component';
import { BookDropOffRoutingModule } from './book-dropoff-routing.module';
import { BookDropOffComponent } from './book-dropoff.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SharedComponentsUiModule,
  FormBuilderModule,
} from '@domgen/dgx-components';
import { ClaimSharedModule } from '../_shared/claim-shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './components/map/map.component';
import { DropoffOriginComponent } from './components/dropoff-origin/dropoff-origin.component';
import { DropoffListComponent } from './components/dropoff-list/dropoff-list.component';
import { DropoffContainerComponent } from './components/dropoff-container/dropoff-container.component';
import { DropoffLocationComponent } from './components/dropoff-location/dropoff-location.component';
import { BookingAnalyticsService } from '@domgen/dgx-components';
import { SharedUtilQuantumMetricsModule } from '@domgen/dgx-fe-util-foundation';

const components = [
  BookDropOffComponent,
  MapComponent,
  MapTabComponent,
  MapTabsComponent,
  DropoffOriginComponent,
  DropoffListComponent,
  DropoffContainerComponent,
  DropoffLocationComponent,
];
@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    BookDropOffRoutingModule,
    SharedComponentsUiModule,
    ClaimSharedModule,
    FormBuilderModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    SharedUtilQuantumMetricsModule.forRoot(),
  ],
  providers: [BookingAnalyticsService],
})
export class BookDropOffModule {}
