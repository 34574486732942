import { Injectable } from '@angular/core';

export interface PaginationState {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

@Injectable()
export class PaginationService {
  getPagination(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10
  ) {
    // Calculate total pages and define startPage and endPage variables
    const totalPages = Math.ceil(totalItems / pageSize);
    const startPage = 1;
    const endPage = totalPages;

    // Calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // Create an array of pages to *ngFor in the list of pages
    const pages = Array.from(
      Array(endPage + 1 - startPage),
      (_, i) => startPage + i
    );

    // Return object with all pagination properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    } as PaginationState;
  }
}
