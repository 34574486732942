<h2>Confirm if you’d like to book:</h2>

<div class="notice">
  <div class="xs-12--none flex mb-1">
    <svg class="icon icon-calendar">
      <use xlink:href="#icon-calendar"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--block mr-1 mb-0">Engineer visit date</h5>
      <p class="xs--block">{{ day?.date | date: 'd MMMM YYYY' }}</p>
    </div>
  </div>
  <div class="xs-12--none flex">
    <svg class="icon icon-clock">
      <use xlink:href="#icon-clock"></use>
    </svg>
    <div class="flex-column">
      <h5 class="typog-body-bold xs--block mr-1 mb-0">Time slot</h5>
      <p class="xs--block mr-half">
        An engineer will be in touch to confirm the arrival time.
      </p>
    </div>
  </div>
</div>

<ui-button
  type="submit"
  variant="primary"
  icon="arrow-right"
  data-automation="'ConfirmAD'"
  (click)="selectSlot()"
>
  Book this date
</ui-button>
