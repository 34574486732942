import { State } from '../+state/claim.reducer';
import { Api, ClaimStage } from '@domgen/dgx-components';
import {
  Claim,
  ClaimBundleType,
  ClaimJourneyStateType,
} from '@domgen/dgx-components';
import addDays from 'date-fns/addDays';
import { differenceInDays, eachDayOfInterval, format } from 'date-fns';

const getData: Api.GetMandatoryDataResponse = {
  Status: 'OK',
  StatusCode: 'GM000',
  StatusDebugging: [],
  BookingOEM: 'Hoover Candy',
  ProductType: 'WASHING MACHINE',
  PlanInWarrantyPeriod: false,
  PlanInWaitPeriod: false,
  InWarrantyContactName: 'Hoover',
  InWarrantyContactTel: '0121292991',
  OEMModelDataRequired: true,
  UniqueApplianceID: '128080',
  ModelNumber: 'LBHF130E CE',
  FaultData: {
    FaultCategoryID: 665,
    FaultCategoryName: '',
    FaultCategoryLabel: '',
    FaultCategoryPriority: 0,
    PossibleAnswers: [
      {
        FaultID: '32929',
        FaultCode: '1ZV',
        FaultName: '1ZV - Burning Smell',
        FaultLabel: 'Burning smell',
        Priority: '1',
      },
      {
        FaultID: '32726',
        FaultCode: '1ZL',
        FaultName: '1ZL - Appearance-Damage',
        FaultLabel: 'Comestic damage',
        Priority: '2',
      },
      {
        FaultID: '32719',
        FaultCode: '1ZB',
        FaultName: '1ZB - Does Not Drain',
        FaultLabel: 'Doesnt drain',
        Priority: '3',
      },
      {
        FaultID: '32713',
        FaultCode: '1Z4',
        FaultName: '1Z4 - Does Not Dry',
        FaultLabel: 'Doesnt dry',
        Priority: '4',
      },
      {
        FaultID: '32930',
        FaultCode: '1Z2',
        FaultName: '1Z2 - Does Not Fill',
        FaultLabel: 'Doesnt fill with water',
        Priority: '5',
      },
      {
        FaultID: '32718',
        FaultCode: '1ZA',
        FaultName: '1ZA - Does Not Heat',
        FaultLabel: 'Doesnt heat',
        Priority: '6',
      },
      {
        FaultID: '32931',
        FaultCode: '1Z9',
        FaultName: '1Z9 - Does Not Rinse',
        FaultLabel: 'Doesnt rinse',
        Priority: '7',
      },
      {
        FaultID: '32932',
        FaultCode: '1Z6',
        FaultName: '1Z6 - Does Not Spin',
        FaultLabel: 'Doesnt spint',
        Priority: '8',
      },
      {
        FaultID: '32933',
        FaultCode: '1Z8',
        FaultName: '1Z8 - Does Not Take,or takes Wrong Detergent',
        FaultLabel: 'Doesnt take detergent',
        Priority: '9',
      },
      {
        FaultID: '32934',
        FaultCode: '1Z7',
        FaultName: '1Z7 - Does Not Wash',
        FaultLabel: 'Doesnt wash',
        Priority: '10',
      },
      {
        FaultID: '32935',
        FaultCode: '1ZH',
        FaultName: '1ZH - Door Faulty',
        FaultLabel: 'Faulty door',
        Priority: '11',
      },
      {
        FaultID: '32725',
        FaultCode: '1ZJ',
        FaultName: '1ZJ - Door Hinge Faulty',
        FaultLabel: 'Faulty door hinge',
        Priority: '12',
      },
      {
        FaultID: '32710',
        FaultCode: '1XX',
        FaultName: '1XX - Inspection Required',
        FaultLabel: 'Inspection required',
        Priority: '13',
      },
      {
        FaultID: '32936',
        FaultCode: '1ZC',
        FaultName: '1ZC - Leaks',
        FaultLabel: 'Leaking',
        Priority: '14',
      },
      {
        FaultID: '32721',
        FaultCode: '1ZD',
        FaultName: '1ZD - Noisy',
        FaultLabel: 'Noisy',
        Priority: '15',
      },
      {
        FaultID: '32937',
        FaultCode: '1ZE',
        FaultName: '1ZE - Overheats',
        FaultLabel: 'Overheats',
        Priority: '16',
      },
      {
        FaultID: '32723',
        FaultCode: '1ZG',
        FaultName: '1ZG - Tears',
        FaultLabel: 'Tears clothing',
        Priority: '17',
      },
      {
        FaultID: '32938',
        FaultCode: '1Z1',
        FaultName: '1Z1 - Appliance Dead',
        FaultLabel: 'Wont switch on',
        Priority: '18',
      },
      {
        FaultID: '32930',
        FaultCode: '1Z12',
        FaultName: 'Annual Service',
        FaultLabel: 'Annual Service',
        Priority: '19',
      },
    ],
  },
  OEMApplianceData: [
    {
      FieldIdentifier: 'UniqueApplianceID',
      FieldLabel: 'Model Number',
      FieldType: 'String',
      FieldRegex: null,
      FieldLookupRegex: null,
      FieldValues: [],
      DataCategory: 'OEMAppliances',
      DataType: 'ModelNumber',
      UseGetData: true,
      DisplayPriority: 0,
    },
    {
      FieldIdentifier: 'SerialNumber',
      FieldLabel: 'Serial Number',
      FieldType: 'String',
      FieldRegex: null,
      FieldLookupRegex: null,
      FieldValues: [],
      DataCategory: null,
      DataType: null,
      UseGetData: false,
      DisplayPriority: 0,
    },
  ],
  ClaimTypes: [
    {
      ClaimTypeID: '3',
      ClaimTypeName: 'Breakdown',
      ClaimTypeLabel: 'Breakdown',
      ClaimTypeCode: 'BD',
      ClaimTypeAvailable: true,
    },
    {
      ClaimTypeID: '4',
      ClaimTypeName: 'Damage Caused by Accident',
      ClaimTypeLabel: 'Damage Caused by Accident',
      ClaimTypeCode: 'AD',
      ClaimTypeAvailable: true,
    },
  ],
};

const reflect: Api.Reflect = {
  countryCode: 'GB',
  journeyState: ClaimJourneyStateType.START,
  claimType: ClaimBundleType.REPAIR,
  applianceCode: '1C',
  makerCode: 'XXX',
  ttl: '',
  domain: 'https://www.vm.domesticandgeneral.com',
  homeTelephone: '02086612014',
  addressLine1: '12 Paxton Terrace',
  productType: 'Washing Machine',
  addressLine2: 'London',
  companyCode: 'D',
  addressLine3: 'Pimlico',
  mobilePhone: '07707654556',
  addressLine4: 'Pimlico',
  postCode: 'SW1V 3DA',
  initials: 'Mr',
  manufacturer: 'Hoover',
  planNumber: 'VU50017405',
  status: 'N',
  customerTitle: 'Mr',
  surname: 'Malak',
  firstName: 'Morhys',
  email: 'morhys@tester.com',
  workPhone: '1221323',
  user: 'xxx',
  rootURL: 'https://www.domesticandgeneral.com',
  myAccountURL: 'https://www.myaccount.domesticandgeneral.com',
  logoutPath: '/logout',
  personalDetailsPath: '/personaldetails',
  dashboardPath: '/dashboard',
  isRiskOwner: 'false',
};

export const claim: Claim = {
  getData: getData,
  reflect: reflect,
  bookingAvailable: false,
};

const calendar = getAvailibityData();

export const claimWithBooking: Claim = {
  ...claim,
  bookingOption: {
    Status: 'OK',
    StatusCode: 'PS000',
    StatusDebugging: [],
    PaymentRequired: false,
    ManualReferralAllowed: true,
    ManualReferralServiceOptionID: 30,
    ExtraAvailability: true,
    AuthorityLimit: '100',
    AvailabilityRequestDays: 7,
    AvailabilityMaximumDays: 28,
    AvailabilityStartDate: format(calendar.daysForward[0], 'yyyy-MM-dd'),
    AvailabilityEndDate: format(
      calendar.daysForward[calendar.daysForward.length - 1],
      'yyyy-MM-dd'
    ),
    AvailabilityData: calendar.calendarDaysForward as Api.BookingDate[],
  },
};

const calendarSlots = getAvailibityData(true);

export const claimWithBookingSlots: Claim = {
  ...claim,
  bookingOption: {
    Status: 'OK',
    StatusCode: 'PS000',
    StatusDebugging: [],
    PaymentRequired: false,
    ManualReferralAllowed: true,
    ManualReferralServiceOptionID: 30,
    ExtraAvailability: true,
    AuthorityLimit: '100',
    AvailabilityRequestDays: 7,
    AvailabilityMaximumDays: 28,
    AvailabilityStartDate: format(calendarSlots.daysForward[0], 'yyyy-MM-dd'),
    AvailabilityEndDate: format(
      calendarSlots.daysForward[calendarSlots.daysForward.length - 1],
      'yyyy-MM-dd'
    ),
    AvailabilityData: calendarSlots.calendarDaysForward as Api.BookingDate[],
  },
};

function getAvailibityData(withSlots = false) {
  const startDate = new Date();
  const endDate = addDays(new Date(), 6);
  const daysFromStart = differenceInDays(endDate, new Date());

  const daysForward = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const slots = [
    {
      Identifier: '1062_20200217_0715_1300',
      EndTime: '13:00',
      StartTime: '07:15',
      Available: true,
      SlotType: 'Specific',
    },
    {
      Identifier: '1062_20200217_0715_2001',
      EndTime: '20:00',
      StartTime: '07:15',
      Available: true,
      SlotType: 'Specific',
    },
    {
      Identifier: '1062_20200217_1000_2000',
      EndTime: '20:00',
      StartTime: '10:00',
      Available: true,
      SlotType: 'Specific',
    },
    {
      Identifier: '1062_20200217_1200_2000',
      EndTime: '20:00',
      StartTime: '12:00',
      Available: true,
      SlotType: 'Specific',
    },
  ];

  const allday = [
    {
      Identifier: null,
      SlotType: 'AllDay',
      StartTime: '',
      EndTime: '',
      Available: true,
    },
  ];

  const calendarDaysForward = daysForward
    .filter(() => {
      return Math.random() >= 0.3;
    })
    .map((date) => {
      return date
        ? {
            Date: format(date, 'yyyy-MM-dd'),
            Slots: withSlots ? slots : allday,
          }
        : null;
    });

  return {
    daysForward,
    calendarDaysForward,
  };
}

export const claimBokingComplete: Claim = {
  ...claimWithBooking,
  userPersonalDetailsUpdated: { successfull: false, changed: true },
  claimReference: '',
  customer: {
    ClaimID: '15566',
    CustomerTitle: 'Mr',
    CustomerFirstName: 'Morhys',
    CustomerLastName: 'Malak',
    CustomersEmail: 'morhys@tester.com',
    CustomersMobile: '07707654556',
    CustomersLandLine: '02086612014',
    CustomersHouseStreetName: '12 Paxton Terrace',
    CustomersTownCity: 'London',
    CustomersLocalArea: 'Pimlico',
    CustomersPostCode: 'SW1V 3DA',
  },
  claimConfirmed: {
    Status: 'OK',
    StatusCode: 'RD000',
    StatusDebugging: [],
    ClaimID: '83303',
    ClaimReference: '',
    PlanNumber: 'VU60084712',
    ManufacturerName: 'Apple',
    ProductGroup: 'Cooker Hood',
    ProductType: 'Cooker Hood',
    ModelNumber: 'UNKNOWN COOKER HOOD',
    SerialNumber: null,
    FaultCategorySelected: 'Common',
    FaultSelected: 'It sounds different',
    ClaimQuestionsArray: [
      {
        QuestionID: '403',
        QuestionLabel: 'When did the fault occur?',
        AnswerID: '1037',
        AnswerLabel: 'AnswerLabel',
        AnswerType: 'DATE',
        AnswerHelp: 'AnswerHelp',
        AnswerValue: 'Mon Nov 22 2021 00:00:00 GMT+0000 (Greenwich Mean Time)',
      },
      {
        QuestionID: '404',
        QuestionLabel: 'Tell us about the fault',
        AnswerID: '1038',
        AnswerLabel: 'AnswerLabel',
        AnswerType: 'RESPONSE_FIELD',
        AnswerHelp: 'AnswerHelp',
        AnswerValue: 'sf',
      },
    ],
    ServiceOption: 'Home Repair',
    AppointmentDate: '2021-11-29',
    AppointmentSlot: 'ANY',
    ExcessAmount: 0,
    AuthorityLimit: '300',
    ServiceProviderID: '1373',
    ServiceProviderCompanyName: 'FLYINGTOOLBOX RESPOND',
    ServiceProviderBuildingName: 'Swan Court',
    ServiceProviderHouseStreetName: '11 Worple Road',
    ServiceProviderLocalArea: 'London',
    ServiceProviderTownCity: 'Wimbledon',
    ServiceProviderPostCode: 'SW19 4JS',
    ServiceProviderTelephone: '020 1234 1234',
    ServiceProviderEmail: 'ServiceProviderPublicEmail@flyingtoolboxrespond.com',
    ServiceProviderWebsite: '',
    ServiceProviderHours: {
      Monday: { OpeningTime: '09:00:00', ClosingTime: '17:00:00' },
      Tuesday: { OpeningTime: '09:00:00', ClosingTime: '17:00:00' },
      Wednesday: { OpeningTime: '09:00:00', ClosingTime: '17:00:00' },
      Thursday: { OpeningTime: '09:00:00', ClosingTime: '17:00:00' },
      Friday: { OpeningTime: '09:00:00', ClosingTime: '17:00:00' },
      Saturday: { OpeningTime: '00:00:00', ClosingTime: '00:00:00' },
      Sunday: { OpeningTime: '00:00:00', ClosingTime: '00:00:00' },
    },
    ContactName: '',
    ContactAddressType: '',
    ContactBuilding: '',
    ContactHouseStreetName: '',
    ContactLocalArea: '',
    ContactTownCity: '',
    ContactPostCode: '',
    ContactMobile: '',
    ContactEmail: '',
    ProblemOccurredDate: null,
  },
  complete: true,
};

export const claimBokingCallbackComplete: Claim = {
  ...claimBokingComplete,
  claimSelection: {
    selectionState: {
      modelNumberAlreadyValidated: true,
      claimStage: ClaimStage.CreateClaim,
      faultType: { id: '187', description: 'WASHING NOT SATISFACTORY' },
      faultTypeAnswer: {
        id: '26509',
        description: 'SETTING WATER HARDNESS LEVEL',
      },
    },
    request: {
      ClaimTypeID: '3',
      Manufacturer: 'HOTPOINT',
      ProductType: 'DISHWASHER',
      FaultCategoryID: 187,
      FaultID: '26509',
    },
  },
  callbackQA: {
    FaultDate: 'Tue Nov 23 2021 00:00:00 GMT+0000 (Greenwich Mean Time)',
    Description: 'wqe',
  },
  customer: {
    ClaimID: '15566',
    CustomerTitle: 'Mr',
    CustomerFirstName: 'Morhys',
    CustomerLastName: 'Malak',
    CustomersEmail: 'morhys@tester.com',
    CustomersMobile: '07707654556',
    CustomersLandLine: '02086612014',
    CustomersHouseStreetName: '12 Paxton Terrace',
    CustomersTownCity: 'London',
    CustomersLocalArea: 'Pimlico',
    CustomersPostCode: 'SW1V 3DA',
  },
  callbackConfirmation: {
    claimID: '',
    reference: '21-619BCBCAC722E',
    callbackTime: 'Thu Nov 25 2021 11:20:20 GMT+0000 (Greenwich Mean Time)',
  },
};

export const claimBokingErrorFallback: Claim = {
  ...claimBokingComplete,
  claimConfirmed: {
    Status: 'OK',
    StatusCode: '',
    StatusDebugging: [],
    ClaimID: '',
    ProductGroup: '',
    ProductType: '',
    ClaimReference: '',
    PlanNumber: claimBokingComplete?.reflect?.planNumber as string,
    ManufacturerName: claimBokingComplete?.reflect?.manufacturer as string,
    ProductDescription: claimBokingComplete?.reflect?.productType,
    ModelNumber: claimBokingComplete?.getData?.ModelNumber as string,
    SerialNumber: null,
    FaultCategorySelected: claimBokingComplete?.claimSelection?.selectionState
      ?.faultTypeAnswer?.description as string,
    FaultSelected: claimBokingComplete?.claimSelection?.selectionState
      ?.faultType?.description as string,
    ClaimQuestionsArray: claimBokingComplete?.questions
      ? claimBokingComplete?.questions?.map((q) => {
          return {
            QuestionID: q.question.QuestionID,
            QuestionLabel: q.question.QuestionLabel,
            AnswerID: q.answer?.AnswerID as string,
            AnswerLabel: '',
            AnswerType: q.question.AnswerType,
            AnswerHelp: '',
            AnswerValue: q.answer?.AnswerValue as string,
          };
        })
      : [],
    ServiceOption: claimBokingComplete.bookedServiceOption as string,
    AppointmentDate: claimBokingComplete.bookingSelection?.date as string,
    AppointmentSlot: claimBokingComplete.bookingSelection?.slot as string,
    ExcessAmount: 0,
    AuthorityLimit: '',
    ServiceProviderID: '',
    ServiceProviderCompanyName: '',
    ServiceProviderBuildingName: '',
    ServiceProviderHouseStreetName: '',
    ServiceProviderLocalArea: '',
    ServiceProviderTownCity: '',
    ServiceProviderPostCode: '',
    ServiceProviderTelephone: '',
    ServiceProviderEmail: '',
    ServiceProviderWebsite: '',
    ServiceProviderHours: {},
    ContactName: '',
    ContactAddressType: '',
    ContactBuilding: '',
    ContactHouseStreetName: '',
    ContactLocalArea: '',
    ContactTownCity: '',
    ContactPostCode: '',
    ContactMobile: '',
    ContactEmail: '',
  },
  errorResponse: true,
};

export const initialState = <State>(<unknown>{
  entities: [claim],
  isLoading: false,
  planNumber: 'VU50017405',
});
