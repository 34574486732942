import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarDay, CalendarSlot } from '../../../interfaces';

@Component({
  selector: 'dg-booking-calendar-day-all-day-slot',
  templateUrl: './booking-calendar-day-all-day-slot.component.html',
  styleUrls: ['./booking-calendar-day-all-day-slot.component.scss'],
})
export class BookingCalendarDayAllDaySlotComponent {
  @Input() day?: CalendarDay;
  @Output() selected: EventEmitter<CalendarSlot> = new EventEmitter();

  selectSlot() {
    const slot = Array.isArray(this.day?.slots)
      ? this.day?.slots[0]
      : this.day?.slots;
    this.selected.emit(slot);
  }
}
