<div class="time-selector">
  <a
    class="item"
    [ngClass]="{ active: amAcitve, disabled: amSlots.length === 0 }"
    (click)="onSelectTimeGroup('AM')"
    >AM</a
  >
  <a
    class="item"
    [ngClass]="{ active: !amAcitve, disabled: pmSlots.length === 0 }"
    (click)="onSelectTimeGroup('PM')"
    >PM</a
  >
</div>

<div>
  <div
    class="xs-row time-group"
    *ngIf="amAcitve"
    [style.height.px]="maxHeight"
    [ngClass]="{
      'time-group-row': amSlots.length === 2,
      'time-group-single': amSlots.length === 1
    }"
  >
    <div *ngFor="let time of amSlots" class="xs-6--none">
      <div
        class="time"
        #item
        (click)="onSelect(time)"
        [ngClass]="{ active: activeTime === time }"
        [class.disabled]="!time.available"
      >
        <span>{{ time.startTime }} - {{ time.endTime }}</span>
        <span *ngIf="!time.available">Unavailable</span>
      </div>
    </div>
  </div>

  <div
    class="xs-row time-group"
    *ngIf="!amAcitve"
    [style.height.px]="maxHeight"
    [ngClass]="{
      'time-group-row': pmSlots.length === 2,
      'time-group-single': amSlots.length === 1
    }"
  >
    <div *ngFor="let time of pmSlots" class="xs-6--none">
      <div
        class="time"
        (click)="onSelect(time)"
        [ngClass]="{ active: activeTime === time }"
        [class.disabled]="!time.available"
      >
        <span>{{ time.startTime }} - {{ time.endTime }}</span>
        <span *ngIf="!time.available">Unavailable</span>
      </div>
    </div>
  </div>
</div>
