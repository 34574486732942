import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import type {
  FormValues,
  FormDataSchema,
  FormControlsDefinition,
} from '../../formbuilder/interfaces/form-controls-definition.interface';
import { FormsService } from '../services/forms.service';

@Component({
  selector: 'dg-fb-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  public form?: FormGroup;
  public pageValues?: FormValues;
  public pageItems?: FormDataSchema['meta'];
  public useValidation = false;

  @Input() data?: FormDataSchema;
  @Output() formData: EventEmitter<any> = new EventEmitter<FormGroup>();
  @Output() customButtonEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private formsService: FormsService) {}

  createForm(pageItems: FormControlsDefinition[], pageValues: FormValues) {
    this.form = new FormGroup(
      this.formsService.defineformControls(pageItems, pageValues)
    );
  }

  onCreateForm() {
    this.pageItems = this.data?.meta;
    this.pageValues = this.data?.formValues || {};
    this.createForm(
      this.pageItems?.groups as FormControlsDefinition[],
      this.pageValues
    );
  }

  ngOnInit() {
    this.onCreateForm();
  }

  /**
   * return form and set default angular validation
   */
  onSubmit() {
    this.formData.emit(this.form);
    this.useValidation = true;
  }
}
