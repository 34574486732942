import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { CalendarDay } from '../../../interfaces';

export enum Navigate {
  LEFT = 'left',
  RIGHT = 'right',
}

@Component({
  selector: 'dg-booking-calendar-navigation',
  templateUrl: './booking-calendar-navigation.component.html',
  styleUrls: ['./booking-calendar-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCalendarNavigationComponent implements OnChanges {
  @Input() weeks?: any;
  @Input() pager?: any;
  @Input() extraAvailibilty: boolean | undefined = undefined;
  @Output() navigate: EventEmitter<Navigate> = new EventEmitter();

  navigateType = Navigate;

  currentWeek?: {
    start: Date;
    end: Date;
  };

  ngOnChanges() {
    this.currentWeek = {
      start: (this.weeks as CalendarDay[])[0].date,
      end: (this.weeks as CalendarDay[])[
        (this.weeks as CalendarDay[]).length - 1
      ].date,
    };
  }
}
