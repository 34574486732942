import { Component } from '@angular/core';

@Component({
  selector: 'ao-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ao-claims';
}
