import { Injectable } from '@angular/core';
import {
  HeaderLinksService,
  ClaimFacade,
  ChatClientService,
  UserService,
} from '@domgen/data-access-claims';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClaimsAppComponentService {
  links$ = this.headerLinks.links$;
  loadingState$: Observable<{
    isLoading: boolean;
    message: string | null;
  }> = this.claimFacade.loadingState$;

  constructor(
    private readonly user: UserService,
    private readonly chatClient: ChatClientService,
    private readonly headerLinks: HeaderLinksService,
    private readonly claimFacade: ClaimFacade
  ) {
    this.chatClient.routeListener().subscribe();
  }

  logout(routeToLogin: boolean, homepageURL: string = '') {
    this.user.logout(routeToLogin, homepageURL).subscribe();
  }
}
