import { Claim, ServiceOptionTypes } from '../interfaces/claim-state.interface';
import { claim } from './claim-state';

export const ClaimMock: Claim = {
  ...claim,
  bookedServiceOption: ServiceOptionTypes.Home,
  claimConfirmed: {
    Status: 'OK',
    StatusCode: 'RD000',
    StatusDebugging: [],
    ClaimID: '32475',
    ClaimReference: '',
    PlanNumber: 'VU40028569',
    ManufacturerName: 'Candy',
    ProductDescription: 'WASHING MACHINE',
    ModelNumber: 'LB C30J ARG',
    SerialNumber: null,
    FaultCategorySelected: 'Fault Codes',
    FaultSelected: 'Doesnt drain',
    ServiceOption: 'Home Repair',
    AppointmentDate: '2020-01-15',
    AppointmentSlot: 'ANY',
    ServiceProviderID: '1064',
    ServiceProviderCompanyName: 'HOOVER CANDY GROUP',
    ServiceProviderBuildingName: '',
    ServiceProviderHouseStreetName: 'Breightmet Industrial Estate',
    ServiceProviderLocalArea: 'Bury Road',
    ServiceProviderTownCity: 'Bolton',
    ServiceProviderPostCode: 'BL2 6PU',
    RepairSlot: '',
    ProductGroup: '',
    ProductType: '',
    ServiceProviderTelephone: '0344 499 5599',
    ServiceProviderEmail: 'hoover@candy.com',
    ServiceProviderWebsite: 'www.service.hoover.co.uk/',
    ServiceProviderHours: {
      Monday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Tuesday: {
        OpeningTime: '08:30:00',
        ClosingTime: '17: 00:00',
      },
      Wednesday: {
        OpeningTime: '08: 30:00',
        ClosingTime: '17:  00: 00',
      },
      Thursday: {
        OpeningTime: '08: 30:  00',
        ClosingTime: '17: 00: 00',
      },
      Friday: {
        OpeningTime: '08: 30: 00',
        ClosingTime: '17: 00: 00',
      },
      Saturday: {
        OpeningTime: '00:00:  00',
        ClosingTime: '00:  00:00',
      },
      Sunday: {
        OpeningTime: '10: 00: 00',
        ClosingTime: '16: 00: 00',
      },
    },
    ContactName: 'Ben Dikson',
    ContactAddressType: '',
    ContactBuilding: '23A',
    ContactHouseStreetName: 'Some Street',
    ContactLocalArea: 'Bexley',
    ContactTownCity: 'London',
    ContactPostCode: 'E4 13FS',
    ContactMobile: '07565432123',
    ContactEmail: 'some@email.com',
  },
};
