<div class="alert-dialog">
  <div class="alert-dialog__content flex">
    <div class="alert-dialog__main">
      <div class="alert-dialog__icon">
        <svg class="icon icon-alerts">
          <use xlink:href="#icon-alerts"></use>
        </svg>
      </div>
      <div class="alert-dialog__main-body">
        <h4>Something went wrong</h4>
        <p>
          Sorry, we’ve run into an error. Please try again now or come back
          later.
        </p>
        <p class="mt-1">
          We’ll retry for you automatically in {{ countdown$ | async }}.
        </p>
      </div>
    </div>
    <div class="alert-dialog__action mt-2">
      <ui-button
        type="submit"
        variant="primary"
        icon="arrow-right"
        (click)="onClick()"
        data-automation="'ConfirmAD'"
      >
        Try again now
      </ui-button>
    </div>
  </div>
</div>
