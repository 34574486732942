import { Component, Output, EventEmitter, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import type { ErrorContent } from '../../interfaces/error-page.interface';

export interface DialogRetryData {
  timer: number;
}

@Component({
  selector: 'dg-modal-dialog-retry',
  templateUrl: './modal-dialog-retry.component.html',
  styleUrls: ['./modal-dialog-retry.component.scss'],
})
export class ModalDialogRetryComponent {
  @Input() content?: ErrorContent;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  public countdown$: Observable<string>;

  constructor(
    public dialogRef: MatDialogRef<ModalDialogRetryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogRetryData
  ) {
    const time = this.data.timer / 1000 + 1;

    this.countdown$ = timer(0, 1000).pipe(
      take(time),
      map((v) => {
        const remaining = time - 1 - v;
        return `${remaining} second${remaining === 1 ? '' : 's'}`;
      }),
      finalize(() => this.dialogRef.close())
    );
  }

  onClick() {
    this.dialogRef.close();
  }
}
