import { ClaimHelperService } from '../services/claim-helper.service';
import { format } from 'date-fns';
import { AdminScheme, CalendarDay, CalendarSlot } from '@domgen/dgx-components';
import { Claim, ServiceOptionTypes } from '@domgen/dgx-components';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import * as claimActions from './claim.actions';
import * as fromClaims from './';
import {
  first,
  filter,
  debounceTime,
  map,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormCollectionData } from '@domgen/dgx-components';
import { Origin } from '@domgen/dgx-components';
import type { Api } from '@domgen/dgx-components';
import { ServiceOption } from '@domgen/dgx-components';
import { SetOneProductType } from './claim.actions';
import { adminSchemes } from '../services/admin-schemes';

@Injectable({
  providedIn: 'root',
})
export class ClaimFacade {
  productTypes$: Observable<string[] | undefined> = this._store
    .select(fromClaims.getProductTypes)
    .pipe(first());

  claimLoaded$: Observable<boolean | null> = this._store
    .select(fromClaims.getLoaded)
    .pipe(filter<boolean>(Boolean));

  activeClaim$: Observable<Claim> = this._store
    .select(fromClaims.getActiveClaim)
    .pipe(filter<Claim | undefined>(Boolean)) as Observable<Claim>;

  bookingDates$ = this._store
    .select(fromClaims.bookingDates)
    .pipe(
      distinctUntilChanged(
        (
          prev: Api.PutServiceOptionResponse | null,
          curr: Api.PutServiceOptionResponse | null
        ) => prev?.AvailabilityStartDate === curr?.AvailabilityStartDate
      )
    );

  bookingError$ = this._store.select(fromClaims.getBookingErrors);

  serviceOption$ = this._store.select(fromClaims.serviceOption);

  worldpay$ = this._store.select(fromClaims.worldpay);

  serviceOptions$ = this._store.select(fromClaims.serviceOptions).pipe(
    withLatestFrom(this.serviceOption$),
    map(
      ([serviceOptions, serviceOption]: [
        ServiceOption[] | null,
        ServiceOption | null
      ]) => {
        const hasEngineerBooking = serviceOptions?.find(
          (o: ServiceOption) => o.ServiceOptionType === ServiceOptionTypes.Home
        );

        const serviceOptionsCached = serviceOptions?.map((s) => ({
          ...s,
          ...(serviceOption && {
            CurrentlySelected:
              s.ServiceOptionID === serviceOption?.ServiceOptionID,
          }),
        }));

        return hasEngineerBooking ? [hasEngineerBooking] : serviceOptionsCached;
      }
    )
  );

  dropOffOrigin$ = this._store.select(fromClaims.dropOffOrigin);

  getManufacturers$ = this._store.select(fromClaims.getManufacturers);

  dropoffLocations$ = this._store.select(fromClaims.dropoffLocations);

  getSuperCategory$ = this._store.select(fromClaims.getSuperCategory);

  loadingState$ = this._store
    .select(fromClaims.isLoading)
    .pipe(debounceTime(100));

  constructor(
    private readonly _store: Store<fromClaims.State>,
    private readonly _claimHelper: ClaimHelperService
  ) {}

  setProductTypes(payload: string[] | undefined) {
    this.dispatch(claimActions.SetProductTypes({ payload }));
  }

  setOneProductType(payload: SetOneProductType) {
    this.dispatch(
      claimActions.SetOneProductType({
        payload,
      })
    );
  }

  seenGasSafetyNotice() {
    this.dispatch(claimActions.SeenGasSafetyNotice());
  }

  showLoader(show: boolean) {
    this.dispatch(
      show ? claimActions.Loading({ payload: null }) : claimActions.Loaded()
    );
  }

  clearBookingErrors() {
    this.dispatch(claimActions.ClearBookingErrors());
  }

  getBookingExtraSlots() {
    this.dispatch(claimActions.GetBookingExtraSlots({}));
  }

  getSilentExtraSlots() {
    this.dispatch(claimActions.SilentGetBookingExtraSlots({}));
  }

  cancelSilentGetBookingExtraSlots() {
    this.dispatch(claimActions.CancelSilentGetBookingExtraSlots());
  }

  submitBooking(
    claim: Claim,
    currentDate: CalendarDay,
    currentDateSlotTime: CalendarSlot,
    collectionData: FormCollectionData,
    firstAvailableDate: string
  ) {
    this.dispatch(
      claimActions.PutRepairData({
        payload: {
          request: {
            ClaimID: claim?.claimID as string,
            PlanNo: claim?.reflect?.planNumber as string,
            ExcessPaid: false,
            ExtraInformation: '',
            AppointmentDate: format(currentDate.date, 'yyyy-MM-dd'),
            AppointmentSlotIdentifier:
              this._claimHelper.getBookingSlotIdentifier(
                currentDate,
                currentDateSlotTime,
                claim.isRebook
              ) as string,
            CollectionData: this._claimHelper.getCollectionData(collectionData),
          },
          bookingSelection: {
            date: format(currentDate.date, 'dd MMM yyyy'),
            slot:
              currentDateSlotTime.slotType === 'Specific'
                ? `${currentDateSlotTime.startTime} - ${currentDateSlotTime.endTime}`
                : 'All day',
            collectionData: collectionData,
            firstAvailableDate: firstAvailableDate,
          },
          customerDetails: claim.customer,
          isRebook: claim.isRebook,
        },
      })
    );
  }

  submitDropOffBooking(
    claim: Claim,
    url: string,
    provider: Api.ServiceProvider,
    collectionData: FormCollectionData
  ) {
    this.dispatch(
      claimActions.PutRepairData({
        payload: {
          request: {
            ClaimID: claim?.claimID as string,
            PlanNo: claim?.reflect?.planNumber as string,
            ExcessPaid: false,
            ServiceProviderID: provider.ServiceProviderID,
            ExtraInformation: '',
            AppointmentDate: '',
            AppointmentSlotIdentifier: '',
            CollectionData: this._claimHelper.getCollectionData(collectionData),
          },
          bookingSelection: {
            url: url,
            provider: provider,
            collectionData: collectionData,
          },
          customerDetails: claim.customer,
          isRebook: claim.isRebook,
        },
      })
    );
  }

  submitSelfSendBooking(
    claim: Claim,
    collectionData: FormCollectionData | null
  ) {
    this.dispatch(
      claimActions.PutRepairData({
        payload: {
          request: {
            ClaimID: claim?.claimID as string,
            PlanNo: claim?.reflect?.planNumber as string,
            ExcessPaid: false,
            ExtraInformation: '',
            AppointmentDate: '',
            AppointmentSlotIdentifier: '',
            CollectionData: this._claimHelper.getCollectionData(collectionData),
          },
          bookingSelection: {
            collectionData: collectionData as FormCollectionData,
          },
          customerDetails: claim.customer,
          isRebook: claim.isRebook,
        },
      })
    );
  }

  submitManualBooking(serviceOptionID: number) {
    this.dispatch(
      claimActions.ManualBooking({
        payload: {
          noBookingDatesFound: true,
          serviceOptionID: String(serviceOptionID),
        },
      })
    );
  }

  submitCallbackBooking(claim: Claim) {
    this.dispatch(
      claimActions.PutRepairData({
        payload: {
          request: {
            ClaimID: claim?.claimID as string,
            PlanNo: claim?.reflect?.planNumber as string,
            ExcessPaid: false,
            ExtraInformation: '',
            AppointmentDate: '',
            AppointmentSlotIdentifier: '',
          },
          customerDetails: claim?.customer,
          isRebook: claim.isRebook,
        },
      })
    );
  }

  getDropOffLocation(claim: Claim, origin: Origin) {
    this.dispatch(
      claimActions.DropOffBooking({
        payload: {
          dropOffOrigin: origin,
        },
      })
    );
  }

  onCompleteClaimDetails(selectedServiceOption: ServiceOption, claim: Claim) {
    if (claim.excess?.required) {
      const address = {
        addressLine1: claim.customer?.CustomersHouseStreetName as string,
        addressLine2: claim.customer?.CustomersLocalArea as string,
        addressLine3: claim.customer?.CustomersTownCity as string,
        addressLine4: '',
        postalCode: claim.customer?.CustomersPostCode as string,
        countryCode: 'GBR',
      };
      this.dispatch(
        claimActions.LoadCardPaymentRedirect({
          payload: {
            request: {
              companyCode: claim?.reflect?.companyCode as string,
              schemeCode: claim?.reflect?.schemeCode as string,
              amount: Number(claim?.excess?.cost),
              currency: 'GBP',
              description: 'Claims',
              name: claim.customer?.CustomerFirstName as string,
              email: claim.customer?.CustomersEmail as string,
              transactionSource: this.getTransactionSource(
                claim?.reflect?.companyCode as string
              ),
              transactionType: 'W',
              referenceNumber: claim.reflect?.planNumber?.slice(3) as string,
              mobTel: claim.customer?.CustomersMobile as string,
              address: address,
              billingAddress: address,
              ipAddress: '1.1.1.1',
            },
            selectedServiceOption: selectedServiceOption,
          },
        })
      );
    } else {
      this.selectServiceOption(selectedServiceOption);
    }
  }

  getTransactionSource(companyCode: string) {
    switch (companyCode) {
      case 'A':
        return 'X';
      case 'E':
        return 'X';
      case 'D':
        return 'K';
      default:
        return 'K';
    }
  }

  selectServiceOption(selectedServiceOption: ServiceOption) {
    this.dispatch(claimActions.ResetBooking());
    this.dispatch(
      claimActions.SelectServiceOption({
        payload: selectedServiceOption,
      })
    );
  }

  completePayment(request: Api.PaymentRequest) {
    this.dispatch(claimActions.CompletePayment({ payload: request }));
  }

  getAdminScheme(reflect: Api.Reflect): AdminScheme | undefined {
    const { planNumber } = reflect;
    const scheme = planNumber?.slice(0, 3);
    return adminSchemes.filter((item) => item.SchCode === scheme)[0];
  }

  dispatch(action: Action) {
    this._store.dispatch(action);
  }
}
