export interface ProductDetails {
  title: string;
  caption?: string;
  subtitle?: string;
  brand?: string;
  applianceName?: string;
  planNumber?: string;
  modelNumber?: string;
  rebrand?: boolean;
}
