<div class="mt-4 pb-4">
  <div class="sm-stack md-row lg-row my-account-banner justify--center">
    <div class="xs-12--none md-7 my-account-wrapper">
      <h3 class="main-title">{{ heading }}</h3>
      <h2 class="sub-title">{{ description }}</h2>
      <ui-button
        type="submit"
        variant="primary"
        [ngClass]="{}"
        icon="arrow-right"
        (click)="routeToMyAccount()"
      >
        {{ buttonLabel }}
      </ui-button>
    </div>
    <div
      class="xs-12--none md-5 md--block my-account-image"
      style="background-image: url('./assets/images/my-account.jpg')"
    ></div>
  </div>
</div>
