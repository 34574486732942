import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { BehaviorSubject } from 'rxjs';
import { fadeInAnimation } from '../../animations/animations';
import { CalendarDay, CalendarSlot } from '../../interfaces';
import { Availability } from '../../interfaces/claim-state.interface';
import { CalendarHelperService } from '../calendar/services/calendar-helper.service';
import { SlotType } from './booking-calendar-day/booking-calendar-day.component';
import { Navigate } from './booking-calendar-navigation/booking-calendar-navigation.component';
import { PaginationService } from './services/pagination.service';

@Component({
  selector: 'dg-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
  providers: [PaginationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class BookingCalendarComponent implements OnChanges {
  @Input() availability?: Availability;
  @Input() initialPage = 1;
  @Input() pageSize = 7;

  @Output() selectDateTimeSlot: EventEmitter<{
    day: CalendarDay;
    slot: CalendarSlot;
  }> = new EventEmitter();
  @Output() requestExtraSlots: EventEmitter<Boolean> = new EventEmitter();

  days?: CalendarDay[] = [];
  slotType?: SlotType;
  weeks = new BehaviorSubject([]) as BehaviorSubject<CalendarDay[] | []>;

  pager = this.paginate.getPagination(
    this.days?.length as number,
    this.initialPage,
    this.pageSize
  );
  firstDate?: CalendarDay;
  selected: CalendarDay | null = null;
  initialized = false;

  constructor(
    private _calendarHelper: CalendarHelperService,
    private paginate: PaginationService
  ) {}

  onDaySlotSelected(selected: { day: CalendarDay; slot: CalendarSlot }) {
    this.selectDateTimeSlot.emit(selected);
  }

  onDaySelected(day: CalendarDay | null) {
    if (day?.slotsAvailable) {
      this.selected = day;
      const week = this.days
        ?.slice(this.pager?.startIndex, (this.pager?.endIndex as number) + 1)
        ?.map((d) => {
          return { ...d, selected: d.date === day?.date && !day.selected };
        });
      this.weeks.next(week as CalendarDay[]);
    }
  }

  onSelectFirstDate() {
    const position =
      differenceInCalendarDays(this.firstDate?.date as Date, new Date()) + 1;

    const todayDatePage =
      position < this.pager.pageSize
        ? this.pager?.startPage
        : Math.ceil(position / this.pager.pageSize);

    this.setPage(todayDatePage);
    this.onDaySelected(this.firstDate as CalendarDay);
  }

  ngOnChanges() {
    this.days = this._calendarHelper.buildUIDaysForward(
      this.availability?.bookingDates as CalendarDay[],
      new Date(this.availability?.firstStartDate as string),
      new Date(this.availability?.endDate as string)
    );

    this.firstDate = this.days?.find((d) => d.slotsAvailable);

    this.slotType =
      Array.isArray(this.firstDate?.slots) &&
      !this.firstDate?.slots?.find((e) => e.slotType === SlotType.ALLDAY)
        ? SlotType.SPECIFIC
        : SlotType.ALLDAY;

    this.pager = this.paginate.getPagination(
      this.days?.length as number,
      this.initialPage,
      this.pageSize
    );

    if (this.days && this.days.length) {
      this.setPage(
        this.initialized ? this.pager.endPage : this.pager.startPage
      );
      this.initialized = true;
    }
  }

  setPage(page: number) {
    // get page
    this.pager = this.paginate.getPagination(
      this.days?.length as number,
      page,
      this.pageSize
    );

    // get new page of days from days array
    const week = this.days?.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.weeks.next(week as CalendarDay[]);
  }

  navigate(value: Navigate) {
    if (
      value === Navigate.LEFT &&
      this.pager?.startPage < this.pager?.currentPage
    ) {
      this.setPage(this.pager?.currentPage - 1);
    }

    if (
      value === Navigate.RIGHT &&
      this.pager?.endPage >= this.pager?.currentPage
    ) {
      const lastPage = this.pager?.endPage === this.pager?.currentPage;
      if (lastPage && this.availability?.extraAvailability) {
        this.requestExtraSlots.emit(true);
      } else if (!lastPage) {
        this.setPage(this.pager?.currentPage + 1);
      }
    }
  }
}
