<div
  class="calendar-day"
  [ngClass]="{
    'calendar-day--disabled': !day?.slotsAvailable,
    'calendar-day--active': day?.selected
  }"
  id="{{ day?.date }}"
>
  <div class="calendar-day__row flex" (click)="daySelected.emit(day)">
    <div
      class="
        calendar-date
        flex-column
        text--center
        justify--center
        align--center
      "
    >
      <span class="xs--block typog-body-bold">{{
        day?.date | date: 'EEE'
      }}</span
      ><span class="xs--block typog-body-bold">{{
        day?.date | date: 'd'
      }}</span>
    </div>
    <div
      *ngIf="slotType === slotTypes.ALLDAY"
      class="calendar-info flex justify--center align--center typog-body-bold"
    >
      {{ day?.slotsAvailable ? 'Date available' : 'Fully booked' }}
    </div>
    <div
      class="calendar-slots flex justify--center align--center typog-body-bold"
      *ngIf="slotType === slotTypes.SPECIFIC && day?.slotsAvailable"
    >
      {{ day?.slots?.length }}
    </div>
    <div
      *ngIf="slotType === slotTypes.SPECIFIC"
      class="calendar-info flex justify--center align--center typog-body-bold"
    >
      {{
        day?.slotsAvailable
          ? day?.slots?.length === 1
            ? 'time slot'
            : 'time slots'
          : 'Fully booked'
      }}
    </div>
    <div
      class="calendar-icon-arrow flex justify--center align--center"
      *ngIf="day?.slotsAvailable"
    >
      <span
        class="icon icon--xs icon-arrow-down justify--center align--center"
        [ngClass]="{
          'icon-arrow-down': !day?.selected,
          'icon-arrow-up': day?.selected
        }"
      ></span>
    </div>
  </div>

  <dg-booking-calendar-day-all-day-slot
    *ngIf="day?.selected && slotType === slotTypes.ALLDAY"
    [@slideInOut]
    (@slideInOut.start)="animationStart()"
    [day]="day"
    (selected)="onSlotSelect($event)"
  >
  </dg-booking-calendar-day-all-day-slot>

  <dg-booking-calendar-day-specific-slot
    *ngIf="day?.selected && slotType === slotTypes.SPECIFIC"
    [day]="day"
    [@slideInOut]
    (@slideInOut.start)="animationStart()"
    (selected)="onSlotSelect($event)"
  >
  </dg-booking-calendar-day-specific-slot>
</div>
