import { ServiceOption } from './service-option.interface';
import {
  ClaimJourneyStateType,
  ClaimBundleType,
} from './claim-state.interface';

export namespace Api {
  export interface Response<R> {
    status: string;
    result: R;
  }

  export interface OrbitParameters {
    [key: string]: any;
  }
  export interface Reflect {
    asvOffered?: string;
    countryCode?: string;
    claimType?: ClaimBundleType;
    journeyState?: ClaimJourneyStateType;
    applianceCode?: string;
    makerCode?: string;
    ttl?: string;
    homeTelephone?: string;
    addressLine1?: string;
    productType?: string;
    addressLine2?: string;
    companyCode?: string;
    addressLine3?: string;
    mobilePhone?: string;
    addressLine4?: string;
    postCode?: string;
    initials?: string;
    user?: string;
    manufacturer?: string;
    planNumber?: string;
    status?: string;
    customerTitle?: string;
    surname?: string;
    firstName?: string;
    email?: string;
    workPhone?: string;
    claimId?: string;
    rootURL?: string;
    myAccountURL?: string;
    logoutPath?: string;
    personalDetailsPath?: string;
    dashboardPath?: string;
    domain?: string;
    catalogId?: string;
    storeId?: string;
    langId?: string;
    isRiskOwner?: string;
    decision?: string;
    crossSellReqSource?: string;
    crossSellReqAction?: string;
    store?: string;
    schemeCode?: string;
  }

  export interface StartTransactionRequest {
    PlanNumber: string;
    Manufacturer: string;
    ProductType: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface StartTransactionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: [];
    GUID?: string;
  }

  export interface GetDataRequest {
    DataCategory: string;
    DataType?: string;
    Parameters?: [];
    SearchTerm: string;
    ChannelCode?: string;
    CountryCode?: string;
    GUID?: string;
  }

  export interface GetQuestionRequest {
    GUID?: string;
    ClaimID: string;
    QuestionID: number | null;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface GetQuestionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: [];
    QuestionID: string;
    QuestionLabel: string;
    QuestionHelp: string;
    AnswerType: string;
    AnswerData: any[];
    AnswerValues?: string[];
  }

  export interface DataResult {
    DataIdentifier: string;
    DataLabel: string;
  }

  export interface GetDataResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: [];
    DataName: string;
    DataResult: DataResult[];
  }

  export interface GetMandatoryDataRequest {
    GUID: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface GetMandatoryDataResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    BookingOEM: string | null;
    ProductType: string;
    PlanInWarrantyPeriod: boolean;
    PlanInWaitPeriod: boolean;
    InWarrantyContactName?: any;
    InWarrantyContactTel?: any;
    OEMModelDataRequired: boolean;
    UniqueApplianceID: string | null;
    ModelNumber: string;
    PlanNumber?: string;
    SerialNumber?: string;
    FaultData: FaultData[] | FaultData;
    OEMApplianceData: OEMApplianceData[];
    ClaimTypes: ClaimType[];
    IWCallbackRequired?: boolean;
    IWCallbackData?: IWCallbackData;
  }

  export interface IWCallbackData {
    OEMLabel?: string;
    SLA?: string;
    Fields: IWCallbackDataField[];
  }

  export interface IWCallbackDataField {
    Identifier: string;
    Type: string;
    Label: string;
    Hint: string;
    Mandatory: boolean;
    MaximumLength: number;
  }

  export interface IWCallbackCustomerData {
    Description?: string;
    FaultDate?: string;
    CustomerTitle?: string;
    CustomerFirstName?: string;
    CustomersLasName?: string;
    CustomersEmail?: string;
    CustomersMobile?: string;
    CustomersLandline?: string;
    CustomersHouseStreetName?: string;
    CustomersTownCity?: string;
    CustomersLocalArea?: string;
    CustomersPostCode?: string;
    CallbackSlot?: string;
  }

  export interface PossibleAnswer {
    FaultID: string;
    FaultCode: string;
    FaultName: string;
    FaultLabel: string;
    Priority: string;
  }

  export interface FaultData {
    FaultCategoryID: number;
    FaultCategoryName: string;
    FaultCategoryLabel: string;
    FaultCategoryPriority: number;
    PossibleAnswers: PossibleAnswer[];
  }

  export interface ClaimType {
    ClaimTypeID: string;
    ClaimTypeName: string;
    ClaimTypeLabel: string;
    ClaimTypeCode: 'BD' | 'AD' | 'ASV' | 'BDBOCO' | 'BDBOSY' | 'MAINT';
    ClaimTypeAvailable: boolean;
    IWEnabled?: boolean;
  }

  export interface OEMApplianceData {
    FieldIdentifier: string;
    FieldLabel: string;
    FieldType: string;
    FieldRegex: string | null;
    FieldLookupRegex: string | null;
    FieldValues: string[];
    DataCategory: string | null;
    DataType: string | null;
    UseGetData: boolean;
    DisplayPriority: number;
  }

  export interface GetPlanDataRequest {
    PlanNumbers: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface GetPlanDataResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    PlanNumber: string;
    ApplianceData: [
      {
        Manufacturer: string;
        ManufacturerGroup: string;
        ModelNumber: string;
        SerialNumber: string;
        ProductType: string;
      }
    ];
    ClaimTypes: [
      {
        ClaimTypeID: string;
        ClaimTypeName: string;
        ClaimTypeLabel: string;
      }
    ];
    RequiredAttributes: [
      {
        AttributeName: string;
        DataCategory: string;
        UseGetData: boolean;
        Mandatory: boolean;
        AttributeValues: string[];
      }
    ];
  }

  export interface GetServiceOptionRequest {
    ClaimID: string;
    CustomerTitle: string;
    CustomerFirstName: string;
    CustomerLastName: string;
    CustomersEmail: string;
    CustomersMobile: string;
    CustomersLandLine: string;
    CustomersHouseStreetName: string;
    CustomersTownCity: string;
    CustomersLocalArea: string;
    CustomersPostCode: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface GetServiceOptionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    ServiceOptions: ServiceOption[];
    PaymentData?: PaymentData[];
    PaymentRequired?: boolean;
  }

  export interface PaymentData {
    Type: string;
    Label: string;
    Amount: string;
  }
  export interface GetTransactionRequest {
    GUID: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface GetTransactionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    PlanNumber: string;
    Manufacturer: string;
    ProductType: string;
    UniqueApplianceID: number;
    ModelNumber: string;
    SerialNumber: string;
    ClaimTypeID: string;
    FaultCategoryID: string;
    FaultID: string;
  }

  export interface PutAnswerRequest {
    ClaimID: string;
    QuestionID: string;
    AnswerID: string;
    AnswerValue: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface PutAnswerResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    NextQuestionID?: any;
    ClaimState: string;
    ClaimStateMessage?: any;
  }

  export interface PutNewClaimRequest {
    GUID?: string;
    CustomerTitle: string;
    CustomersEmail: string;
    CustomersMobile: string;
    CustomersLandLine: string;
    CustomerFirstName: string;
    CustomerLastName: string;
    CustomersHouseStreetName: string;
    CustomersTownCity: string;
    CustomersLocalArea: string;
    CustomersPostCode: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface PutNewClaimResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    ClaimID: string;
    PaymentRequired: boolean;
    PaymentsRequired: any[];
  }

  export interface PutRepairDataRequest {
    ClaimID: string;
    PlanNo: string;
    ExcessPaid: boolean;
    AppointmentDate: string;
    AppointmentSlotIdentifier: string;
    ServiceProviderID?: string;
    CollectionData?: CollectionData;
    ExtraInformation?: any;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface CollectionData {
    COLLECTION_ADDRESS?: CollectionDataAddress;
    DELIVERY_RETURN_ADDRESS?: CollectionDataAddress;
  }

  export interface CollectionDataAddress {
    BuildingName?: string;
    HouseStreetName?: string;
    LocalArea?: string;
    TownCity?: string;
    PostCode?: string;
  }

  export interface PutRepairDataResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    ClaimID: string;
    ClaimReference: string;
    PlanNumber: string;
    ManufacturerName: string;
    ProductDescription?: string;
    ServiceOption: string;
    AppointmentDate?: string | Date;
    RepairSlot?: string;
    ServiceProviderID: string;
    ServiceProviderCompanyName: string;
    ServiceProviderBuildingName: string;
    ServiceProviderHouseStreetName: string;
    ServiceProviderLocalArea: string;
    ServiceProviderTownCity: string;
    ServiceProviderPostCode: string;
    ServiceProviderTelephone: string;
    ServiceProviderEmail: string;
    ServiceProviderWebsite: string;
    AppointmentSlot?: string;
    ProductGroup: string;
    ProductType: string;
    ModelNumber: string;
    SerialNumber: string | null;
    FaultCategorySelected: string;
    FaultSelected: string;
    ContactName: string;
    ContactAddressType: string;
    ContactBuilding: string;
    ContactHouseStreetName: string;
    ContactLocalArea: string;
    ContactTownCity: string;
    ContactPostCode: string;
    ContactMobile: string;
    ContactEmail: string;
    AuthorityLimit?: string;
    ClaimQuestionsArray?: {
      AnswerHelp: string;
      AnswerID: string;
      AnswerLabel: string;
      AnswerType: string;
      AnswerValue: string;
      QuestionID: string;
      QuestionLabel: string;
    }[];
    AvailabilityData?: [
      {
        Date: string;
        Slots: [
          {
            Identifier: string;
            SlotType: string;
            StartTime: string;
            EndTime: string;
            Available: boolean;
          }
        ];
      }
    ];
    PaymentData?: [
      {
        Type: string;
        Amount: string;
        Label?: string;
      }
    ];
    ExcessAmount?: number;
    ProblemOccurredDate?: null;
    ServiceProviderHours?: ServiceProviderOpeningHours;
  }

  export interface PutServiceOptionRequest {
    ClaimID: string;
    ServiceOptionID: string;
    ServiceOptionRequiredFields: any;
    AvailabilityEndDate?: string;
    AvailabilityStartDate?: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface PutServiceOptionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: any[];
    PaymentRequired: boolean;
    ExtraAvailability?: boolean;
    AvailabilityRequestDays?: number;
    AvailabilityMaximumDays?: number;
    AvailabilityStartDate?: string;
    AvailabilityEndDate?: string;
    AuthorityLimit?: string;
    ManualReferralAllowed?: boolean;
    ManualReferralServiceOptionID?: number;
    PaymentData?: [
      {
        PaymentLabel?: string;
        PaymentAmount?: number;
      }
    ];
    ServiceProviders?: ServiceProvider[];
    AvailabilityData?: BookingDate[] | [];
  }

  export type ServiceProviderOpeningHours = {
    [index: string]: ServiceProviderOpeningHoursDetails;
  };

  export interface ServiceProviderOpeningHoursDetails {
    ClosingTime: string;
    OpeningTime: string;
  }

  export interface ServiceProvider {
    ServiceProviderID: string;
    ServiceProviderCompanyName: string;
    ServiceProviderBuildingName: string;
    ServiceProviderHouseStreetName: string;
    ServiceProviderLocalArea: string;
    ServiceProviderTownCity: string;
    ServiceProviderPostCode: string;
    ServiceProviderTelephone: string;
    ServiceProviderEmail: string;
    ServiceProviderWebsite: string;
    ServiceProviderHours: ServiceProviderOpeningHours;
    Address?: string;
    Lat?: number;
    Lng?: number;
    Distance?: string;
  }

  export interface BookingDate {
    Date: string;
    Slots: {
      Identifier: string | null;
      SlotType: string;
      StartTime: string;
      EndTime: string;
      Available: boolean;
    }[];
  }

  export interface UpdateTransactionRequest {
    GUID?: string;
    Manufacturer: string;
    ProductType: string;
    UniqueApplianceID?: string;
    CustomOEMModelCode?: string; // ML Code
    ModelNumber?: string;
    SerialNumber?: string;
    ClaimTypeID?: string;
    FaultCategoryID?: string | number;
    FaultID?: string;
    ChannelCode?: string;
    CountryCode?: string;
  }

  export interface UpdateTransactionResponse {
    Status: string;
    StatusCode: string;
    StatusDebugging: Array<any>;
  }

  export interface GetProductAttributesResponse {
    Response: {
      ResponseCode: string;
      ResponseDescription: string;
      ExcessAmount: string;
      ReqAttributes: {
        AttributeLabel: string;
        AnswerType: string;
        AllowedValues?: string[];
        DefaultValue: string;
        IsMandatory: string;
        ValidationRules: string;
      }[];
      PolicyNumber: string;
      APIName: string;
    };
  }

  export interface GetSuperCategoryResponse {
    applianceSuperCategory: string;
    isGasAppliance: boolean;
  }

  export interface ReBookEngineer {
    ChannelCode?: string;
    CountryCode?: string;
    RepairDate: string;
    RepairSlot: string;
    ExtraDetails: string;
  }

  export interface CallbackRequest {
    Fields?: CallbackRequestField[];
  }

  export interface CallbackRequestField {
    Identifier?: string;
    Value?: string;
  }

  export interface CallbackResponse {
    Status: string;
    StatusCode: string;
    CallbackReference: string;
  }

  export interface PaymentRequest {
    claimID: string;
    type: string;
    method: string;
    reference: string;
    amount: number;
    channelCode: string;
    countryCode: string;
  }
  export interface PaymentResponse {
    status: string;
    statusCode: string;
    statusDebugging: any;
  }
}
