import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogRetryComponent } from '@domgen/dgx-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { ClaimFacade } from '../+state/claim.facade';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ErrorPromptHandlerService {
  readonly trigger$ = new BehaviorSubject(false) as BehaviorSubject<
    Boolean | unknown
  >;
  retryCTA = new Subject();
  dialogRef?: MatDialogRef<ModalDialogRetryComponent>;

  constructor(private claimFacade: ClaimFacade, public dialog: MatDialog) {}

  openDialog(countdown: number): void {
    this.dialogRef = this.dialog.open(ModalDialogRetryComponent, {
      data: {
        timer: countdown,
      },
    });

    this.dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.retryCTA.next(true);
      });
  }

  retryStrategy =
    (
      httpsReq: HttpRequest<any>,
      {
        maxRetryAttempts = 2,
        retryDelay = 5000,
        orbitMethods = ['PutServiceOption', 'GetData'],
        excludedUrls = [
          'update-contact-details',
          'signout',
          'get-super-category',
        ],
        errorCodes = ['GE002', 'GE006', 'VB007'],
      }: {
        maxRetryAttempts?: number;
        retryDelay?: number;
        orbitMethods?: string[];
        excludedUrls?: string[];
        errorCodes?: string[];
      } = {}
    ) =>
    (attempts: Observable<HttpErrorResponse>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          // exluded from retry
          if (
            this.excludedOrbitMethods(orbitMethods, httpsReq) ||
            this.excludedRequests(excludedUrls, error) ||
            (this.errorCodeNoMatch(errorCodes, error) &&
              this.notHttpError0(error) &&
              this.bookingError(httpsReq, error))
          ) {
            return throwError(error);
          }

          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          if (retryAttempt > maxRetryAttempts) {
            return throwError(error);
          }

          this.claimFacade.showLoader(false);
          this.openDialog(retryDelay);

          return this.retryCTA.asObservable().pipe(
            take(1),
            tap(() => {
              this.dialogRef?.close();
              this.claimFacade.showLoader(true);
            })
          );
        })
      );
    };

  private notHttpError0(error: HttpErrorResponse): boolean {
    return error?.status !== 0;
  }

  private bookingError(httpsReq: HttpRequest<any>, error: HttpErrorResponse) {
    if (error.error.ErrorCode === 'RD004') {
      return true;
    }

    return !(
      httpsReq?.body?.method === 'PutRepairData' && error?.status === 504
    );
  }

  private excludedRequests(
    excludedUrls: string[],
    error: HttpErrorResponse
  ): boolean {
    return excludedUrls.includes(error?.url as string);
  }

  private errorCodeNoMatch(
    errorCodes: string[],
    error: HttpErrorResponse
  ): boolean {
    return !errorCodes.includes(error?.error?.ErrorCode);
  }

  private excludedOrbitMethods(
    orbitMethods: string[],
    httpsReq: HttpRequest<any>
  ): boolean {
    return orbitMethods.includes(httpsReq?.body?.method);
  }
}
