<div
  class="row--padding-top-large"
  [ngClass]="{
    'bg--page-alt': displayConfiguration.greyBackground,
    'row--padding-bottom-large': displayConfiguration.showAdditionalText
  }"
>
  <div
    class="picker"
    #picker
    [ngClass]="{ 'picker-extra-slots': canRequestExtraAvailability }"
  >
    <div class="xs-row">
      <div
        class="xs-12 text--center"
        *ngIf="!displayConfiguration.showMonthBelowDay"
      >
        <h2 class="heading__highlight">
          <span>{{ currentDate | date: 'LLLL' }}</span>
          {{ currentDate | date: 'y' }}
        </h2>
        <span *ngIf="startDirection && showUIRows && nextAvailableDate"
          >Next available date:&nbsp;&nbsp;&nbsp;
          <strong
            class="link"
            [attr.data-automation]="'nextavailabledate'"
            (click)="findFirstAvailableDate()"
            >{{ nextAvailableDate | date: 'dd/MM/yyyy' }}</strong
          ></span
        >
      </div>
    </div>
    <div
      *ngIf="showUIRows || displayConfiguration.showUIRows"
      class="container"
    >
      <div class="xs-row justify--center">
        <div class="md-6 divide"></div>
      </div>
    </div>

    <div class="picker-wrapper" #pickerWrapper>
      <div class="xs-row picker-container" #pickerContainer>
        <div
          class="days-wrapper"
          (mousedown)="lock($any($event))"
          (touchstart)="lock($any($event))"
          (mouseup)="move($any($event))"
          (touchend)="move($any($event))"
          [ngClass]="{
            'reduce-height': !displayConfiguration.showDayOfWeek
          }"
          #daysWrapper
        >
          <div class="xs-row days" #days>
            <div
              class="xs-row flex-column-reverse align-items-center day"
              [ngClass]="{
                'month-display': displayConfiguration.showMonthBelowDay,
                'month-display-pad-right':
                  displayConfiguration.showMonthBelowDay && i === selectedDayId,
                'text-display-increase-padding': !!day.text
              }"
              *ngFor="let day of calendarDays; let i = index"
            >
              <input
                type="radio"
                name="days{{ groupID }}"
                [id]="i"
                [attr.data-automation]="'calendar' + i"
                [attr.checked]="day.selected"
                [ngClass]="{
                  active: day.slotsAvailable
                }"
              />
              <label
                class="xs-row align--center justify--around label"
                [for]="i"
                (click)="handleSelectDate(i, $event)"
                [ngClass]="{
                  'is-hidden': !day.slotsAvailable,
                  'month-display': displayConfiguration.showMonthBelowDay
                }"
              >
                <span>{{ day.date | date: 'dd' }}</span>
                <span *ngIf="displayConfiguration.showMonthBelowDay">
                  {{ day.date | date: 'LLL' }}
                </span>
              </label>

              <div
                class="xs-row align--center justify--center label label--dud"
                [ngClass]="{ 'is-hidden': day.slotsAvailable }"
              >
                <span>{{ day.date | date: 'dd' }}</span>
              </div>
              <div class="day__name" *ngIf="displayConfiguration.showDayOfWeek">
                {{ day.date | date: 'EE' }}
              </div>
              <div
                [hidden]="!day.newMonth"
                class="day__month"
                *ngIf="displayConfiguration.showNewMonth"
              >
                <span class="xs-row align--center justify--center month-name">
                  {{ day.date | date: 'LLLL' }}
                </span>
              </div>
              <div
                class="text-display align--center justify--around"
                [ngClass]="{ 'text-display-selected': i === selectedDayId }"
                *ngIf="day.text"
              >
                {{ day.text }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          class="align--center picker-button picker-button--right is-hidden"
          (click)="jumpCount(count + ($any(daysInView) - 1), true)"
          [ngClass]="{ 'flex is-visible': this.showButtonRight }"
          #buttonRight
        >
          <i class="picker-arrow picker-arrow--right"></i>
          <div #buttonSpacer class="spacer"></div>
        </div>
        <div
          class="align--center picker-button picker-button--left is-hidden"
          (click)="jumpCount(count - ($any(daysInView) - 1), false)"
          [ngClass]="{ 'flex is-visible': this.showButtonLeft }"
          #buttonLeft
        >
          <i class="picker-arrow picker-arrow--left"></i>
        </div>
      </div>
    </div>
  </div>
</div>
