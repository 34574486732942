<footer class="footer theme-dark">
  <div class="container">
    <div class="xs-row">
      <div class="xs-6--none">
        <ul class="footer__list footer__list--large">
          <li class="footer__item" *ngFor="let primary of primaryLinks">
            <a
              href="{{ primary.url }}"
              class="link-underline"
              [attr.data-automation]="primary.link"
              >{{ primary.link }}</a
            >
          </li>
        </ul>
      </div>
      <div class="xs-6--none">
        <!-- TrustBox widget - Horizontal -->
        <div
          class="trustpilot-widget"
          #trustPiliot
          data-locale="en-GB"
          data-template-id="5406e65db0d04a09e042d5fc"
          data-businessunit-id="4cb3113600006400050dfb02"
          data-style-height="50px"
          data-style-width="100%"
          data-theme="dark"
        >
          <a
            href="https://uk.trustpilot.com/review/domesticandgeneral.com"
            target="_blank"
            rel="noopener"
            >Trustpilot</a
          >
        </div>
        <!-- End TrustBox widget -->
      </div>
    </div>
    <div class="sm-stack md-row lg-row row--padding">
      <div class="col-12">
        <p class="margin--none">
          Service plans, maintenance & support plans are provided by Domestic &
          General Services Limited. Insurance policies are provided by Domestic
          & General Insurance PLC. Domestic & General Insurance PLC is an
          insurance undertaking, not an intermediary. We are the underwriter of
          the insurance policies and do not provide a personal recommendation or
          advice. Domestic & General Insurance PLC is authorised by the
          Prudential Regulation Authority and regulated by the Financial Conduct
          Authority and the Prudential Regulation Authority (Financial Services
          Register Number 202111). Our address and details of our authorisation
          can be checked on the FCA website or by contacting the FCA on 0800 111
          6768.
        </p>
      </div>
    </div>
    <div class="sm-stack md-row lg-row">
      <div class="col-12">
        <ul class="footer__list">
          <li class="footer__item">
            &copy; {{ date | date: 'yyyy' }} Domestic & General Group Limited
          </li>
          <li class="footer__item" *ngFor="let secondary of secondaryLinks">
            <a
              href="{{ secondary.url }}"
              class="link-underline"
              [attr.data-automation]="secondary.link"
              >{{ secondary.link }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
