<dg-svg></dg-svg>
<div class="full-page">
  <dg-header
    [links]="links$ | async"
    themeClass="header"
    (logout)="service.logout(true)"
  ></dg-header>

  <dg-loader
    *ngIf="(loadingState$ | async)?.isLoading"
    [message]="(loadingState$ | async)?.message"
  ></dg-loader>
  <router-outlet></router-outlet>
</div>

<dg-footer></dg-footer>
