<div class="banner-container" [class.banner-container--stacked]="stacked">
  <div class="container">
    <h2 class="text--center">{{ heading }}</h2>
    <div
      class="xs-row mt-2 xs--middle justify--center align--start"
      *ngIf="!bulletPoints"
    >
      <div class="xs-12 xs-row md-4 lg-4 item" *ngFor="let item of items">
        <svg class="xs-3" [class]="'icon ' + item.imageName">
          <use [attr.xlink:href]="'#' + '' + item.imageName"></use>
        </svg>
        <div class="xs-9">
          <div class="typog-body-large-bold item--heading">
            {{ item.heading }}
          </div>
          <p [innerHTML]="item.description"></p>
        </div>
      </div>
    </div>
    <div *ngIf="bulletPoints">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
