<div *ngIf="slotType" class="container bg--page-alt">
  <div class="xs-row time-picker-wrapper justify--center">
    <div class="xs-12 time-picker bg--page">
      <dg-all-day-slot
        *ngIf="slotType === 'AllDay' && slots"
        [slots]="slots"
        [serviceOptionType]="serviceOptionType"
      ></dg-all-day-slot>
      <dg-specific-slot
        *ngIf="slotType === 'Specific' && slots"
        [slots]="slots"
        [date]="date"
        (selectedTime)="onSelectTime($event)"
      ></dg-specific-slot>
    </div>
  </div>
</div>
