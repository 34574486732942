<div class="xs-row booking">
  <div class="xs-12--none flex justify--center">
    <h3>Week</h3>
  </div>
  <div class="xs-12--none navigation">
    <div class="navigation__cta flex justify--space-between">
      <div
        class="navigation__cta-left"
        (click)="navigate.emit(navigateType.LEFT)"
        [class.navigation__cta-right--disabled]="
          pager?.currentPage === pager?.startPage
        "
      >
        <span
          class="icon icon--xs icon-arrow-left justify--center align--center"
        ></span>
      </div>
      <div
        class="navigation__cta-right"
        (click)="navigate.emit(navigateType.RIGHT)"
        [class.navigation__cta-right--disabled]="
          pager?.currentPage === pager?.endPage && !extraAvailibilty
        "
      >
        <span
          class="icon icon--xs icon-arrow-right justify--center align--center"
        ></span>
      </div>
    </div>
    <div class="navigation__current-week flex justify--center">
      <h2 class="margin--none">
        {{ currentWeek?.start | date: 'd MMM' }} -
        {{ currentWeek?.end | date: 'd MMM' }}
      </h2>
    </div>
  </div>
</div>
