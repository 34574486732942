import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
/* MODULES */
import {
  Config,
  ConfigService,
  SharedComponentsUiModule,
  GA_CLIENT,
  AppProvidersConfigToken,
  AppProvidersConfig,
  ENVIRONMENT,
} from '@domgen/dgx-components';
/* COMPONENTS */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@domgen/util-environment';
import { HttpInterceptorService } from '@domgen/data-access-claims';
import { CookieService } from 'ngx-cookie-service';
import { BookingMapService } from '@domgen/dgx-components';
import { SharedUiSvgModule } from '@domgen/ui-svg';
import { ClaimsDataAccessClaimsModule } from '@domgen/data-access-claims';
import { ClaimsAppComponent } from './claims-app.component';
import { ClaimsFeatureShellRoutingModule } from './claims-feature-shell-routing.module';

export const configFactory = (buildConfigService: ConfigService) => {
  return (): Promise<Config> => {
    return buildConfigService.loadConfigurationData(environment as any);
  };
};
const storeDevToolsModule = !environment.production
  ? [
      StoreDevtoolsModule.instrument({
        name: 'Domestic & General',
        maxAge: 25,
      }),
    ]
  : [];

const gaTagsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).gaTagsConfig;
};
@NgModule({
  declarations: [ClaimsAppComponent],
  exports: [ClaimsAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClaimsFeatureShellRoutingModule,
    SharedComponentsUiModule,
    SharedUiSvgModule,
    HttpClientModule,
    ClaimsDataAccessClaimsModule,
    ...storeDevToolsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER, // ensure the application isn't started before the config file is loaded.
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: GA_CLIENT,
      useFactory: gaTagsTokenFactory,
      deps: [Injector],
    },
    BookingMapService,
    CookieService,
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production },
  ],
})
export class ClaimsFeatureShellModule {
  static forRoot(
    appConfig: AppProvidersConfig
  ): ModuleWithProviders<ClaimsFeatureShellModule> {
    return {
      ngModule: ClaimsFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: appConfig },
        { provide: ENVIRONMENT, useValue: environment },
      ],
    };
  }
}
